import React, { useState } from 'react';
import Header from '../components/common/Header';
import './css/app.css';
import { FaFileInvoice, FaUserCog, FaPhone } from 'react-icons/fa';
import Newsroom from './Newsroom';
import TabbedSection from './TabbedSection';
import OurMission from './OurMission';
import NewsSection from './NewsSection';
import ProductsBanner from './ProductsBanner';

function Homepage() {
    return (
        <>
            <div className="hero-section">

                <Header/>  

                <div className="vertical-quick-links-container">
                    <a href="#" className="quick-link">
                        <FaFileInvoice className="icon" />
                        <span className="link-text">Pay Bill</span>
                    </a>
                    <a href="#" className="quick-link">
                        <FaUserCog className="icon" />
                        <span className="link-text">Manage Account</span>
                    </a>
                    <a href="#" className="quick-link">
                        <FaPhone className="icon" />
                        <span className="link-text">Contact Support</span>
                    </a>
                </div>

            </div> 

            <OurMission/> 

            <TabbedSection/>

            {/* <Newsroom/>  */}

            <NewsSection/>  

            <ProductsBanner/> 

            {/* <Footer/>  */}

        </>
    );
}

export default Homepage;
