import React, { useState } from "react";
import "./css/app.css";
import Aspire from "../assets/images/AspireImg.svg";
import {
  FaBolt,
  FaGlobe,
  FaHeart,
  FaUsers,
  FaShieldAlt,
  FaHandHoldingUsd,
} from "react-icons/fa";
import fuel_img from "../assets/images/renewablegas.png";
import hydrogen_img from "../assets/images/powergeneration.png";
import angeles_logo from "../assets/images/angeles-logo.png";
import CarbonFootprint from "./CarbonFootprint";
import Hero_img from "../assets/images/sustainabality.png";

const Sustainability = () => {
  const [activeTab, setActiveTab] = useState("Energy Solutions");

  const renderContent = () => {
    switch (activeTab) {
      case "Energy Solutions":
        return (
          <div className="content-section">
            <div className="content-block">
              <img src={fuel_img} alt="Clean Fuels" className="content-image" />
              <div className="content-text">
                <h2>Renewable Natural Gas</h2>
                <p>
                  Converting waste products into renewable gas may help
                  California meet its energy needs with local resources.
                </p>
                <button className="learn-more-btn">Learn More</button>
              </div>
            </div>
            <div className="content-block">
              <img
                src={hydrogen_img}
                alt="Hydrogen"
                className="content-image"
              />
              <div className="content-text">
                <h2>Power Generation Technologies</h2>
                <p>
                  Localizing power generation, such as a fuel cell, can increase
                  energy resiliency and power quality.
                </p>
                <button className="learn-more-btn">Learn More</button>
              </div>
            </div>
          </div>
        );

      case "Distribution":
        return (
          <div className="content-section">
            <div className="content-block">
              <img
                src={angeles_logo}
                alt="Clean Fuels"
                className="content-image"
              />
              <div className="content-text">
                <h2>Angeles Link</h2>
                <p>
                  Learn about our plan to develop the nation's largest clean,
                  renewable hydrogen energy pipeline system.{" "}
                </p>
                <button className="learn-more-btn">Learn More</button>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="waysToPay-first-container container-fluid mt-2 first-container">
        <div className="service_hero_img position-relative">
          <img src={Hero_img} alt="Service Hero" className="img-fluid w-100" />
          <h1 className="waysToPay-hero-heading text-start">
          Sustainability Initiatives
          </h1>
        </div>
      </div>

      <div className="waysToPay-five-container container mt-5">
        <h1 className="mb-5 billing-h1">
          Achieving Net Zero Emissions by 2045
        </h1>
        <div className="row">
          <div className="col-md-6 mt-sm-6 mt-md-0 mt-4 border border-primary rounded">
            <img
              src={Aspire}
              alt="assistance progress"
              className="manageHiBill-img img-fluid"
            />
          </div>

          <div className="col-md-6 py-sm-0 px-sm-2 mt-sm-4 mt-md-0">
            <div className="card h-100 text- border-0">
              <div
                className="card-bdy  px-2"
                // style={{
                //   backgroundColor: "rgb(216, 229, 240)",
                //   borderRadius: "10px",
                // }}
              >
                <h4 className="card-text text-dark fw-bold mive mt-sm-3 mt-3 mt-md-0  ">
                  Innovating our business to create lasting benefits for
                  stakeholders by doing the right thing, championing people, and
                  shaping the future.
                </h4>
                <p className=" mt-sm-1 mt-md-2 mt-lg-4">
                  ASPIRE 2045 is our strategy to further integrate
                  sustainability across our business. It builds on our March
                  2021 Climate Commitment to achieve net zero greenhouse gas
                  emissions in our operations and delivery of energy by 2045,
                  and focuses on the five areas below where we can have a
                  strong, positive impact on our communities, our company, and
                  our stakeholders.
                </p>
                <p>
                  For more information on SoCalGas’ support of California’s
                  clean energy goals read the full 2023 Corporate Sustainability
                  Report.
                </p>
                <p>
                  Our sustainability goals strive to align with many of the
                  United Nations Sustainable Development Goals (UN SDGs). They
                  also align with Sempra's sustainability strategy described in
                  its 2023 Sustainability Report.
                </p>
                <p>
                  Learn more about our ASPIRE 2045 Sustainability Strategy from
                  our FAQs and Factsheet.
                </p>
                <div className="d-flex justify-content-center mt-sm-2 mt-md-2 mt-lg-3 mt-xl-3 Billing-btn">
                  <div className="mt-auto">
                    <a href="#" className="mt-3">
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5 mt-5">
        <section className="commitment-section">
          <h1 className="commitment-title heading-h1 mb-5">
            Our commitment to a better tomorrow
          </h1>

          <div className="row row-cols-1 row-cols-md-3 g-4">
            {/* Card 1: Energy Transition */}
            <div className="col">
              <div className="card h-100 commitment-card text-center p-3">
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <FaBolt className="commitment-icon mb-3" />
                  </div>
                  <h3 className="card-title commitment-heading">
                    Energy Transition
                  </h3>
                  <p className="card-text commitment-description ">
                    Advancing the way to clean energy.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 2: Environment */}
            <div className="col">
              <div className="card h-100 commitment-card text-center p-3">
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <FaGlobe className="commitment-icon mb-3" />
                  </div>
                  <h3 className="card-title commitment-heading">Environment</h3>
                  <p className="card-text commitment-description ">
                    Protecting the climate and improving air quality.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 3: Affordability */}
            <div className="col">
              <div className="card h-100 commitment-card text-center p-3">
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <FaHandHoldingUsd className="commitment-icon mb-3" />
                  </div>
                  <h3 className="card-title commitment-heading">
                    Affordability
                  </h3>
                  <p className="card-text commitment-description ">
                    Increasing cleaner energy access and affordability.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 4: Diversity */}
            <div className="col">
              <div className="card h-100 commitment-card text-center p-3">
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <FaUsers className="commitment-icon mb-3" />
                  </div>
                  <h3 className="card-title commitment-heading">Diversity</h3>
                  <p className="card-text commitment-description ">
                    Advancing diversity, equity, and inclusion in the
                    communities we serve.
                  </p>
                </div>
              </div>
            </div>

            {/* Card 5: Safety */}
            <div className="col">
              <div className="card h-100 commitment-card text-center p-3">
                <div className="card-body">
                  <div className="justify-content-center">
                    <FaShieldAlt className="commitment-icon mb-3" />
                  </div>
                  <h3 className="card-title commitment-heading">Safety</h3>
                  <p className="card-text commitment-description ">
                    Achieving world-class safety for our employees.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="container">
        <div className="row">
          <div className="col">
            {" "}
            <h1 className="heading-h1 mt-5 bg-sm-primary bg-md-success bg-lg-danger">
              Driving the Transition to Clean Energy
            </h1>
          </div>
        </div>
      </div>
      <div className="tabs-container">
        <nav className="tab-header">
          <button
            className={activeTab === "Energy Solutions" ? "active" : ""}
            onClick={() => setActiveTab("Energy Solutions")}
          >
            Energy Solutions
          </button>

          <button
            className={activeTab === "Distribution" ? "active" : ""}
            onClick={() => setActiveTab("Distribution")}
          >
            Distribution
          </button>
        </nav>
        <div className="tab-content-container">{renderContent()}</div>
      </div>

      <CarbonFootprint />
    </>
  );
};

export default Sustainability;
