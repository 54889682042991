import React, { useState } from "react";
import "./css/app.css"; // Your custom CSS file
import banner2 from "../assets/images/banner2.jpg"; // Import your images
import banner3 from "../assets/images/banner3.jpg"; // Import your images
import banner4 from "../assets/images/banner4.jpg"; // Import your images

const ProductBanner = () => {
  const [isVisible, setIsVisible] = useState(false); // Toggle state for content visibility

  const toggleVisibility = () => {
    setIsVisible(!isVisible); // Toggle content visibility
  };

  const handleDotClick = (e) => {
    // Remove 'active' class from all dots
    document.querySelectorAll(".carousel-dot").forEach((dot) => {
      dot.classList.remove("active");
    });

    // Add 'active' class to the clicked dot
    e.target.classList.add("active");
  };

  return (
    <>
      <div className="ProductBanner-container container mt-5">
        <h1 className="section-title h1">
          Energy-Saving Products & Promotions
        </h1>
        <div className="row mt-5">
          <div className="col-12">
            <div className="row mt-4 carousel-section-row">
              <div className="col">
                <div
                  id="carouselExampleCaptions"
                  className="carousel slide custom-carousel"
                  data-bs-ride="carousel"
                  data-bs-interval="2000" // 3 seconds auto slide
                >
                  <div className="carousel-inner">
                  
                    <div className="carousel-item active">
                      <img
                        src={banner2}
                        className="d-block w-100 img-fluid custom-carousel-img"
                        alt="Second slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src={banner3}
                        className="d-block w-100 img-fluid custom-carousel-img"
                        alt="Third slide"
                      />
                    </div>
                    <div className="carousel-item">
                      <img
                        src={banner4}
                        className="d-block w-100 img-fluid custom-carousel-img"
                        alt="Fourth slide"
                      />
                    </div>
                  </div>
                </div>

                {/* Custom Dots for carousel navigation */}
                <div className="carousel-dots mt-5 text-center">
                  <input
                    type="radio"
                    name="carousel-dot"
                    id="dot1"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="0"
                    className="carousel-dot"
                    onClick={handleDotClick}
                  />
                  <input
                    type="radio"
                    name="carousel-dot"
                    id="dot2"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="1"
                    className="carousel-dot"
                    onClick={handleDotClick}
                  />
                  <input
                    type="radio"
                    name="carousel-dot"
                    id="dot3"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="2"
                    className="carousel-dot"
                    onClick={handleDotClick}
                  />
                  <input
                    type="radio"
                    name="carousel-dot"
                    id="dot4"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide-to="3"
                    className="carousel-dot"
                    onClick={handleDotClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductBanner;
