import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Homepage from './pages/Homepage.jsx';
import Ways_to_pay from './pages/billingpage/Ways_to_pay.jsx';
import AboutUs from './pages/Aboutus.jsx';
import OurTeam from './pages/OurTeam.jsx';
import Assistance_Programs from './pages/billingpage/Assistance_Programs.jsx';
import Service from './pages/Socal_Service.jsx/Service.jsx';
import ContactUs from './pages/ContactUs.jsx';
import Header from './components/common/Header.jsx';
import Footer from './components/Footer.jsx';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import Sustainability from './pages/Sustainability.jsx';
import Manage_higherBill from './pages/billingpage/Manage_higherBill.jsx';
import Careers from './pages/Careers.jsx';
import Understand_myBill from './pages/billingpage/Understand_myBill.jsx';
import BusinessBilling from './pages/billingpage/BusinessBilling.jsx';
import MarketPlacePg from './MarketPlacePg.js';
import CommingSoon from './CommingSoon.jsx';
import ArticlePg1 from './ArticlePg1.jsx';
import ArticlePg2 from './ArticlePg2.jsx';
import ArticlePg3 from './ArticlePg3.jsx';
import ArticlePg4 from './ArticlePg4.jsx';
import AIDAPg from './AIDAPg.jsx';
import AIDAAboutUs from './AIDAAboutUs.jsx';
import ErrorPage from './ErrorPage'; // Import ErrorPage

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'  
    });
  }, [pathname]);

  return (
    <>
      {pathname !== "/" && pathname !== "/marketPlace" && <Header />}

      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/service-management" element={<Service />} />
        <Route path="/ways-To-Pay" element={<Ways_to_pay />} />
        <Route path="/company-overview" element={<AboutUs />} />
        <Route path="/leadership-team" element={<OurTeam />} />
        <Route path="/sustainability-initiatives" element={<Sustainability />} />
        <Route path="/careers" element={<Careers />} /> 
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/assistance-Program" element={<Assistance_Programs />} />
        <Route path="/managehigh-Bill" element={<Manage_higherBill />} />
        <Route path="/understand-MyBill" element={<Understand_myBill />} />
        <Route path="/business-Billing" element={<BusinessBilling />} />
        <Route path="/marketPlace" element={<MarketPlacePg />} />
        <Route path="/commingSoon" element={<CommingSoon />} />               
        <Route path="/articlepg1" element={<ArticlePg1 />} />               
        <Route path="/articlepg2" element={<ArticlePg2 />} />               
        <Route path="/articlepg3" element={<ArticlePg3 />} />               
        <Route path="/articlepg4" element={<ArticlePg4 />} />               
        <Route path="/aida" element={<AIDAPg />} />               
        <Route path="/aida-about-us" element={<AIDAAboutUs />} />               
        
        {/* Catch-all for undefined routes to show the 404 error page */}
        <Route path="*" element={<ErrorPage code={404} />} />
        <Route path="*" element={<ErrorPage code={503} />} />  

      </Routes>

      {pathname !== "/marketPlace" && <Footer />}
    </>
  );
}

export default App;
