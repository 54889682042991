import React from 'react'

const Testimonials = () => {
  return (
    <>
           
   <div className="container mb-5">
   <h1 className="heading-h1 mt-5">Testinomial</h1>
    <div className="row">
      <div className="col">
      <div className="testinomial-section mt-5">  
      <figure class="snip1157">
  <blockquote>Calvin: You know sometimes when I'm talking, my words can't keep up with my thoughts... I wonder why we think faster than we speak. Hobbes: Probably so we can think twice.
    <div class="arrow"></div>
  </blockquote>
  <img src="https://as2.ftcdn.net/v2/jpg/05/30/01/09/1000_F_530010960_WkmW6q8FIZ42LkEQoSRCLTNTw0WgJqKY.jpg" alt="sq-sample3" />
  <div class="author">
    <h5>Pelican Steve <span> SoCalGas.net</span></h5>
  </div>
</figure>
<figure class="snip1157 hover">
  <blockquote>Thank you. before I begin, I'd like everyone to notice that my report is in a professional, clear plastic binder...When a report looks this good, you know it'll get an A. That's a tip kids. Write it down.
    <div class="arrow"></div>
  </blockquote>
  <img src="https://as1.ftcdn.net/jpg/02/22/85/16/1000_F_222851624_jfoMGbJxwRi5AWGdPgXKSABMnzCQo9RN.jpg" alt="sq-sample27" />
  <div class="author">
    <h5>Max Conversion<span> SoCalGas.net</span></h5>
  </div>
</figure>
<figure class="snip1157">
  <blockquote >My behaviour is addictive functioning in a disease process of toxic co-dependency. I need holistic healing and wellness before I'll accept any responsibility for my actions.
    <div class="arrow"></div>
  </blockquote>
  <img src="https://as2.ftcdn.net/v2/jpg/00/67/20/79/1000_F_67207980_5jNk6I3KFlJjOXY73c3FlfvssaxY5N51.jpg" alt="sq-sample17" />
  <div class="author">
    <h5>Eleanor Faint<span> SoCalGas.net</span></h5>
  </div>
</figure>
</div>
      </div>
    </div>
   </div>
      
    </>
  )
}

export default Testimonials
