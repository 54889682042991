import React from "react";
import "./css/app.css";
import news_img1 from "../assets/images/articleSectionImgPg1.1.jpg";
import news_img2 from "../assets/images/articleSectionImgPg2.1.jpg";
import news_img3 from "../assets/images/articleSectionImgPg3.2.jpg";
import news_img4 from "../assets/images/articleSectionImgPg4.1.jpg";
import { Link } from "react-router-dom";

// Declare multiple page links for each news article
const pageLinks = {
  1: "/articlepg1",
  2: "/articlepg2",
  3: "/articlepg3",
  4: "/articlepg4",
};

const newsItems = [
  {
    id: 1,
    date: "23 MAY 2022",
    title: "Solis STEM Girls Compete Globally",
    image: news_img1,
  },
  {
    id: 2,
    date: "24 MAY 2022",
    title: "SoCalGas Fuel Card Launch",
    image: news_img2,
  },
  {
    id: 3,
    date: "23 APRIL 2022",
    title: `Harvesting for Local Families`,
    image: news_img3,
  },
  {
    id: 4,
    date: "23 APRIL 2022",
    title: "SoCalGas Funds Garden",
    image: news_img4,
  },
];

const NewsSection = () => {
  return (
    <div className="news-section" id="News_section">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
        <h2 className="section-title">The Latest News</h2>        
        <button className="learn-more-btn"> View All News </button>
        </div>
        <div className="news-grid mt-5">
          {newsItems.map((news) => (
            <div key={news.id} className="news-cards">
              <div className="image-container">
                <img
                  src={news.image}
                  alt={news.title}
                  className="news-image ml-0"
                  style={{ padding: "10px", objectFit: "cover" ,borderRadius:"20px"}}
                />

                <div className="date-circle">
                  <span>{news.date.split(" ")[0]}</span>
                  <small>{news.date.split(" ")[1]}</small>
                </div>
              </div>
              <div className="article-label article-bg">Article</div>
              <h3 className="news-title text-center">{news.title}</h3>

              {/* Link to respective article pages based on the news ID */}
             <div className="text-center">
             <Link to={pageLinks[news.id]}>
              <button className="learn-more-btn">Read More </button>
              </Link>
             </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsSection;
