import React, { useState } from 'react';
import './css/app.css'; 
import treeMeasurementImg from '../assets/images/carobonfootprint.png'; 
import hydrogen from '../assets/images/hydrogen.png'; 
import pollution from '../assets/images/pollution.png'; 
import methane from '../assets/images/methane.png'; 

const initiatives = [
  {
    title: 'Methane Reduction',
    description: 'Meeting and surpassing targets to reduce methane emissions by 20% by 2025 and 40% by 2030.',
    detail: 'SoCalGas aims to exceed its targets ahead of schedule, using cutting-edge leak detection and repair technology.',
    icon: methane,
  },
  {
    title: 'Clean Fleet Initiative',
    description: 'Aiming for a 100% zero-emission over-the-road fleet by 2035, reducing carbon footprint sustainably.',
    detail: 'Incorporating more alternative fuel vehicles and using renewable natural gas at all SoCalGas vehicle fueling stations.',
    icon: pollution,
  },
  {
    title: 'Hydrogen Integration',
    description: 'Building hydrogen infrastructure to deliver 20% renewable natural gas to customers by 2030.',
    detail: 'Complete five hydrogen pilot projects by 2025 to support the 2028 Olympics and demonstrate hydrogen safety.',
    icon: hydrogen,
  },
];


const CarbonFootprint = () => {
  const [expanded, setExpanded] = useState(null);

  const handleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <section className="carbon-footprint">
      {/* Section for title and background */}
      <div className="container">
        <div className="row mx-1 border rounded" style={{backgroundColor:"#00206D"}}>
          <div className="col">
        <h1 className='text-white py-5 text-center'> Reducing Our Carbon Footprint for a Cleaner Future    </h1>
        <div className="col">
      </div></div>
        </div>
      </div>

      {/* Full-width image section */}
      <div className="full-width-image mt-3">
        <img src={treeMeasurementImg} alt="Carbon Footprint Initiatives" />
      </div>

      {/* Carbon footprint introduction section */}
      <div className="container intro-section">
        
        <h1 className="heading-h1 mt-5">What is a Carbon Footprint?</h1> 
        <hr />
        <p className="carbon-description ">
          A carbon footprint is the total amount of greenhouse gases (including carbon dioxide and methane) generated by our actions.
          <br/>
          The average carbon footprint per person in the United States is 16 tons, one of the highest rates in the world. Globally, the average carbon footprint is closer to 4 tons. To avoid a 2°C rise in global temperatures, the global carbon footprint per year must drop to under 2 tons by 2050.
          <br/>
          Lowering individual carbon footprints requires small changes in our actions like eating less meat, flying less, and using more energy-efficient methods.
        </p>
      </div>

      {/* Initiatives section */}
      <div className="container mt-5">
  <h1 className="heading-h1 text-center mb-4">
    Our Commitment to Carbon Footprint Reduction
  </h1>
  <p className="overview  text-center">
    <hr className="mb-5" />
    At SoCalGas, we are committed to achieving net zero greenhouse gas emissions by 2045. Our efforts include reducing methane emissions, transitioning to clean fuels, and increasing energy efficiency for all customers.
  </p>

  <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 initiatives-grid">
  {initiatives.map((item, index) => (
    <div className="col" key={index}>
      <div className="card initiative-card h-100 text-center p-3">
        <img
          src={item.icon}
          alt={item.title}
          className="card-img-top initiative-icon mx-auto"
          style={{ height: "200px", objectFit: "cover" }} // Inline CSS for consistent image height
        />
        <div className="card-body">
          <h3 className="card-title initiative-title">{item.title}</h3>
          <p className="card-text initiative-description">{item.description}</p>
          {expanded === index && (
            <p className="card-text initiative-detail">{item.detail}</p>
          )}
          <div className="d-flex justify-content-center">
          <button
            className="mt-3 learn-more-btn"
            onClick={() => handleExpand(index)}
          >
            {expanded === index ? "Show Less" : "Learn More"}
          </button>
          </div>
        </div>
      </div>
    </div>
  ))}
</div>

</div>

    </section>
  );
};

export default CarbonFootprint;
