import React from "react";
import ArticleHeroImg from "./assets/images/ArticleHeroImg4.jpg";
import "./pages/css/app.css";
import articleSectionImg1 from "./assets/images/articleSectionImgPg4.1.jpg";
import articleSectionImg2 from "./assets/images/articleSectionImgPg4.2.jpg";
const ArticlePg1 = () => {
  return (
    <>
      {/* article-first-container */}
      <div className="articlePg-first-container container-fluid mt-2">
        <div className="articlePg_hero_img position-relative">
          <img
            src={ArticleHeroImg}
            alt="Service Hero"
            className="img-fluid w-100"
            style={{ borderRadius: "5px" }}
          />
          <h1 className="articlePg-hero-heading text-start">
            SocalGas NewsRoom
          </h1>
        </div>
      </div>

      {/* article-second-container */}

      <div className="articlePg-second-container container mt-5">
        <div className="row">
          <div className="col text-center">
            <h2>
             Brotherhood Crusade’s New Danny J. Bakewell, Sr. Community Garden Will Serve South LA Families with $75,000 Donation from SoCalGas
            </h2>
            <hr className="mt-4"></hr>
            <div className="col d-flex flex-start">
              <h5 className="text-primary">Oct 9, 2024</h5>
            </div>
            <div className="col">
              <p className=" mt-3">
              LOS ANGELES – Today, Southern California Gas Co. (SoCalGas) presented a $75,000 donation to Brotherhood Crusade to support the development of the new Danny J. Bakewell, Sr. Community Garden at the organization’s headquarters in South Los Angeles. Bakewell served as president and chief executive officer for 35 years at Brotherhood Crusade, a 50-year-old grassroots organization dedicated to improving the lives of low-income, underserved, under-represented and disenfranchised individuals. Los Angeles City Council President Marqueece Harris-Dawson attended the event.
              </p>
            </div>
            <div className="col">
              {" "}
              <img
                src={articleSectionImg1}
                alt=""
                className="mt-3 img-fluid w-100 h-50"
              />
            </div>
            <hr className="mt-4" />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p className=" ">
            The Danny J. Bakewell, Sr. Community Garden is anticipated to open in Spring 2025. In its first year, the garden aims to serve 200 families in South LA, enhancing access to fresh foods and reducing food insecurity. The new garden will allow Brotherhood Crusade to expand its existing Teaching Gardens program, educating students on the importance of fresh produce and the healing power of natural plants like herbs and aloe vera. The garden will also be used for education programs, community receptions, and movie nights.
            </p>

            <p className="">
            “Thanks to the generous grant from SoCalGas, we can significantly enhance our initiatives to promote access to green space, health and wellness in South Los Angeles,” said Brotherhood Crusade’s Chief Executive Officer Charisse Bremond. “We are deeply grateful for their support and thrilled to dedicate this garden in honor of Danny J. Bakewell, Sr., our Institution Builder who led our institution for 35 years and is a lifelong champion of our community. This garden will host educational programs on urban agriculture, nutrition, healing circles, and environmental stewardship, benefiting hundreds of families in the area.”
            </p>

            <p className="">
            Danny J. Bakewell, Sr. currently serves as chairman of the board of Brotherhood Crusade. He has been named one of Los Angeles’ Most Influential Leaders by the Los Angeles Times and has a long history of social justice activism. He co-founded the National Black United Fund and has been a key figure in the Los Angeles Sentinel’s growth since his family purchased the paper in 2004. Bakewell also founded the Taste of Soul Family Festival, now in its 19th year, which attracts over 500,000 people and hundreds of local businesses to Crenshaw Boulevard. The Danny J. Bakewell Sr. Primary Center in South L.A. is named in his honor. His leadership has made Brotherhood Crusade a premier institution in the country.
            </p>

            <p className="">
            "Danny Bakewell has dedicated his life's work to uplifting the community with a sense of both pride and responsibility. He set an expectation to 'do something' and set his family in motion to create a legacy of just that. This garden created in his honor will become a testament to this work, ever growing, ever-changing, and thriving. Thank you to SoCalGas and Brotherhood Crusade for honoring his impact on our community,” said Los Angeles City Council President Marqueece Harris-Dawson.
            </p>
            <hr />
          </div>
        </div>

        <div className="row">

          <div className="col">
            <h2 className="mt-3">
            Los Angeles City Council President Marqueece Harris-Dawson Celebrates Danny Bakewell's Legacy and Impact on the Community Through New Honorary Garden
            </h2>
          </div>

          <div className="col-12">
            <img src={articleSectionImg2} alt="" className="mt-3 img-fluid w-100" />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p className="mt-5 ">
            “SoCalGas is committed to making a positive difference in the communities it serves, and our support for Brotherhood Crusade is a testament to this ongoing dedication. The Danny J. Bakewell, Sr. Community Garden will not only provide fresh, healthy produce but also serve as a green oasis in the heart of the city, a gathering place for residents, enriching the lives of all who visit,” said Andy Carrasco, SoCalGas vice president of communications, local government and community affairs.
            </p>

            <p className="">
            According to a study from the University of Southern California Dornsife, 1.4 million Angelenos lack access to sufficient and healthy food due to barriers like limited grocery stores or lack of public transportation creating food deserts - areas with severely limited access to affordable and good-quality fresh food. Due to their limited options, residents in these food deserts may consume more available fast food and non-nutritious meals, which increases the presence of health issues such as heart disease, diabetes and obesity. With a community garden, South LA residents will have greater access to fresh produce, which they can incorporate into their regular diet and move away from unhealthy ingredients previously available to them.
            </p>

            <p className="">
              Under the ASPIRE 2045 Sustainability Strategy, SoCalGas plans to
              invest $50 million over five years into communities the company
              serves, working to advance racial and gender diversity in the
              workplace and taking tangible steps towards a carbon neutral
              future. By providing resources for higher education and career
              development, SoCalGas aspires to empower communities and help
              prepare young leaders for success.
              SoCalGas’ collaboration with Brotherhood Crusade is part of the company’s ASPIRE 2045 sustainability goals, which includes a plan to invest $50 million to drive positive change in diverse and underserved communities across five years.
            </p>

            <p className="">
            <h4 className="text-primary">About Brotherhood Crusade</h4>
            Brotherhood Crusade was founded in 1968. The organization's principal mission is to champion equality and equity by removing and/or helping individuals overcome the barriers that deter their pursuit of success in life and facilitate opportunities for a better quality of life by effectuating improved health & wellness, social & economic growth, facilitating academic success, promoting personal, providing access to artistic excellence & cultural awareness, increasing financial literacy, and building community agencies & institutions.
              community
            </p>
            <hr className="mt-4" />
          </div>
        </div>

        <div className="row mt-4 article-sm-para">
          <div className="col">
            <p className="">
              This press release contains forward-looking statements within the
              meaning of the Private Securities Litigation Reform Act of 1995.
              Forward-looking statements are based on assumptions about the
              future, involve risks and uncertainties, and are not guarantees.
              Future results may differ materially from those expressed or
              implied in any forward-looking statement. These forward-looking
              statements represent our estimates and assumptions only as of the
              date of this press release. We assume no obligation to update or
              revise any forward-looking statement as a result of new
              information, future events or otherwise.
            </p>
            <p className="">
              In this press release, forward-looking statements can be
              identified by words such as “believe,” “expect,” “intend,”
              “anticipate,” “contemplate,” “plan,” “estimate,” “project,”
              “forecast,” “envision,” “should,” “could,” “would,” “will,”
              “confident,” “may,” “can,” “potential,” “possible,” “proposed,”
              “in process,” “construct,” “develop,” “opportunity,”
              “preliminary,” “initiative,” "target," "outlook," “optimistic,”
              “poised,” “positioned,” “maintain,” “continue,” “progress,”
              “advance,” “goal,” “aim,” “commit,” or similar expressions, or
              when we discuss our guidance, priorities, strategy, goals, vision,
              mission, opportunities, projections, intentions or expectations.
            </p>

            <p className="">
              Factors, among others, that could cause actual results and events
              to differ materially from those expressed or implied in any
              forward-looking statement include: decisions, investigations,
              inquiries, regulations, denials or revocations of permits,
              consents, approvals or other authorizations, renewals of
              franchises, and other actions, including the failure to honor
              contracts and commitments, by the (i) California Public Utilities
              Commission (CPUC), U.S. Department of Energy, U.S. Internal
              Revenue Service and other regulatory bodies and (ii) U.S. and
              states, counties, cities and other jurisdictions therein where we
              do business; the success of business development efforts and
              construction projects, including risks related to (i) completing
              construction projects or other transactions on schedule and
              budget, (ii) realizing anticipated benefits from any of these
              efforts if completed, (iii) obtaining third-party consents and
              approvals and (iv) third parties honoring their contracts and
              commitments; macroeconomic trends or other factors that could
              change our capital expenditure plans and their potential impact on
              rate base or other growth; litigation, arbitration and other
              proceedings, and changes (i) to laws and regulations, including
              those related to tax and trade policy and (ii) due to the results
              of elections; cybersecurity threats, including by state and
              state-sponsored actors, of ransomware or other attacks on our
              systems or the systems of third parties with which we conduct
              business, including the energy grid or other energy
              infrastructure; the availability, uses, sufficiency, and cost of
              capital resources and our ability to borrow money on favorable
              terms and meet our obligations, including due to (i) actions by
              credit rating agencies to downgrade our credit ratings or place
              those ratings on negative outlook, (ii) instability in the capital
              markets, or (iii) rising interest rates and inflation; the impact
              on affordability of our customer rates and our cost of capital and
              on our ability to pass through higher costs to customers due to
              (i) volatility in inflation, interest rates and commodity prices
              and (ii) the cost of meeting the demand for lower carbon and
              reliable energy in California; the impact of climate policies,
              laws, rules, regulations, trends and required disclosures,
              including actions to reduce or eliminate reliance on natural gas,
              increased uncertainty in the political or regulatory environment
              for California natural gas distribution companies, the risk of
              nonrecovery for stranded assets, and uncertainty related to
              emerging technologies; weather, natural disasters, pandemics,
              accidents, equipment failures, explosions, terrorism, information
              system outages or other events, such as work stoppages, that
              disrupt our operations, damage our facilities or systems, cause
              the release of harmful materials or fires or subject us to
              liability for damages, fines and penalties, some of which may not
              be recoverable through regulatory mechanisms or insurance or may
              impact our ability to obtain satisfactory levels of affordable
              insurance; the availability of natural gas and natural gas storage
              capacity, including disruptions caused by failures in the pipeline
              system or limitations on the withdrawal of natural gas from
              storage facilities; and other uncertainties, some of which are
              difficult to predict and beyond our control.
            </p>

            <p className="">
              Sempra Infrastructure, Sempra Infrastructure Partners, Sempra
              Texas, Sempra Texas Utilities, Oncor Electric Delivery Company LLC
              (Oncor) and Infraestructura Energética Nova, S.A.P.I. de C.V.
              (IEnova) are not the same companies as the California utilities,
              San Diego Gas & Electric Company or Southern California Gas
              Company, and Sempra Infrastructure, Sempra Infrastructure
              Partners, Sempra Texas, Sempra Texas Utilities, Oncor and IEnova
              are not regulated by the CPUC.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticlePg1;
