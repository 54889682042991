import React, { useState, useEffect } from 'react';
import slide1 from "../assets/images/AboutUsposter1.jpg";
import slide2 from "../assets/images/AboutUsposter2.jpg";
import slide3 from "../assets/images/AboutUsposter3.jpg";

import Hero_img from "../assets/images/SocalGasAboutUsImg.jpg";
import './css/app.css'


const AboutUsSection = () => {
  const images = [slide1, slide2, slide3];
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <>

     {/* waysToPay-first-container */}

     <div className="waysToPay-first-container container-fluid mt-2 first-container">
  <div className="service_hero_img position-relative">
    <img
      src={Hero_img}
      alt="Service Hero"
      className="img-fluid w-100"
    />
    <h1 className="waysToPay-hero-heading text-start">
      Company Overview
    </h1>
  </div>
</div>


    <div className="container">
        <div className="row">
            <div className="col">
                <h1 className='heading-h1 mt-5'>About Us</h1>
                <hr/>
            </div>
        </div>
    </div>
    <section className="container my-5">
      <div className="card border-0 shadow-lg">
        <div className="row g-0 ">
          {/* Image Carousel */}
          <div className="col-md-6">
            <div className="about-us-image-container w-100 h-100">
              <img
                src={images[currentImage]}
                alt={`Slide ${currentImage + 1}`}
                className='p-2 '
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius:"15px",
                  transition: 'opacity 1s ease-in-out'
                }}
              />
            </div>
          </div>

          {/* Text Content */}
          <div className="col-md-6 ">
            <div className="card-body p-4">
              <h2 className="card-title text-primary text-center">WHO WE ARE</h2>
              <p className="card-text  text-center">
                SoCalGas serves over 21 million consumers across Central and Southern California, covering a vast 24,000-square-mile territory.
              </p>
              <p className="card-text  text-center">
                Dedicated to community engagement and environmental stewardship, SoCalGas works to enhance local quality of life through diversity, sustainability, and innovation.
              </p>
              <p className="card-text  text-center">
              SoCalGas has cut emissions to 0.25% while maintaining reliable, affordable service for 22 million customers.
              </p>
              <p className="card-text  text-center">
                <small className="text-muted">For more details, visit our Investor Relations section.</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>


<div className="about-us-info-sections container">
<div className="about-us-mission">
  <h3 className="dot-bullet">Mission</h3>
  <p>
    SoCalGas's mission to build the cleanest, safest, and most
    innovative energy infrastructure company in America. They are
    committed to achieving Net Zero carbon emissions by 2045. The video
    highlights four innovative technologies that are helping them on
    their journey to Net Zero: renewable gas, power-to-gas, hydrogen
    fuel cells, and carbon capture utilization and sequestration.
    Renewable gas: harnesses waste streams and reuses them to make
    power. Power-to-gas: uses excess renewable electricity to create
    renewable gas and hydrogen, which can be stored, transported, and
    utilized for decades. Hydrogen fuel cells: allow people to reliably
    power their homes, businesses, and transportation with net zero
    emissions. Carbon capture utilization and sequestration: captures
    and utilizes carbon in new production processes for products of the
    future.
  </p>
</div>
<div className="about-us-vision">
  <h3 className="dot-bullet">Vision</h3>
  <p>
    SoCalGas has a history of reducing emissions. They have already
    reduced distribution emission rates to 0.25% while continuing to
    serve their 22 million customers without sacrificing reliability,
    resiliency, or affordability. In 2010, renewable gas wasn't even in
    use, but by 2022, they will reach 5% capacity throughout their
    infrastructure. By 2030, they aim to reach 20% renewable gas
    capacity.
  </p>
</div>

<div className="about-us-vision ">
  <h3 className="dot-bullet">Values</h3>
  <p>
    <b>
      {" "}
      At SoCalGas we live by three values: Do the Right Thing, Champion
      People and Shape the Future.{" "}
    </b>{" "}
    <br />
    These values permeate our company. They guide how we think and drive
    our actions. As one of America’s largest energy providers, our
    values are the lens through which we see every opportunity and
    challenge. With our values providing a strong foundation, our
    mission statement presents an actionable framework of purpose and
    intent. At SoCalGas, we’re dedicated to leading the transition to a
    decarbonized energy system. We believe every Californian deserves a
    clean, affordable, resilient energy future. SoCalGas is working to
    achieve that future through innovation, collaboration, and
    decarbonization.
  </p>
</div>
</div>




</> 
  );
};

export default AboutUsSection;
