import React from 'react';
import { useNavigate } from 'react-router-dom';
import Error404 from './assets/images/ErrorPg404.svg';
import Error503 from './assets/images/Error503.svg';
import CommonError from './assets/images/CommonErrorImg.svg';

import './pages/css/app.css';

const ErrorPage = ({ code }) => {
  const navigate = useNavigate();

  const getMessage = () => {
    switch (code) {
      case 404:
        return "Page Not Found";
      case 503:
        return "Service Unavailable";
      default:
        return "An Unexpected Error Occurred";
    }
  };

  const getImage = () => {
    switch (code) {
      case 404:
        return Error404;
      case 503:
        return Error503;
      default:
        return CommonError;
    }
  };

  return (
    <div className="container" style={{ textAlign: 'center', padding: '2rem' }}>
      <div className='row'>
        <div className='col-12'>
          <img 
            src={getImage()} 
            alt={`Error ${code}`} 
            style={{ maxWidth: '100%', height: '500px' }} 
          />
          {/* <h2>Error {code}</h2>
          <p>{getMessage()}</p> */}
        </div>
        <div className='col'>
          <button 
            onClick={() => navigate('/')} 
            style={{ marginTop: '20px' }} 
            className='learn-more-btn'
          >
            Go Back to Homepage
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
