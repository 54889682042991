import React, { useState } from "react";
import "./css/app.css";
import career from "../assets/images/career-card.png";
import faq_symbol from "../assets/images/faq-symbol.png";
import Testimonials from "./Testimonials";
import Hero_img from "../assets/images/CareerHeroImg.jpg";
import CardIcon1 from "../assets/images/CareerCardIcon1.svg";
import CardIcon2 from "../assets/images/CareerCardIcon2.svg";
import CarrerCardImg1 from '../assets/images/CarrerCardImg1.svg'
import CarrerCardImg2 from '../assets/images/CarrerCardImg2.svg'
const Careers = () => {
 
 

  
  const faqData = [
    {
      question: "How can I check the status of my application?",
      answer:
        'From the Careers Page, click on "Apply Today", which will route you to our applicant tracking system...',
    },
    {
      question: "How do I know if my application was submitted properly?",
      answer:
        "Our application is a two-step process. The first step is to create a profile and upload a resume...",
    },
    {
      question: "Do I have to submit a resume?",
      answer:
        "Yes, the first part of the application process requires you to upload a resume and create a profile in the system...",
    },
    {
      question: "How can I be notified of future opportunities?",
      answer:
        "SoCalGas is featured on a variety of online job platforms, such as LinkedIn and Indeed. You can set up alerts...",
    },
    {
      question: "What benefits does SoCalGas offer?",
      answer: "SoCalGas offers a wide variety of benefits...",
    },
    {
      question:
        "Do I have to live in Southern California to work for SoCalGas?",
      answer:
        "Yes, employees are required to live in Southern California. Exceptions are very rare and based solely on business necessity...",
    },
  ];
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <div className="waysToPay-first-container container-fluid mt-2 first-container">
        <div className="service_hero_img position-relative">
          <img src={Hero_img} alt="Service Hero" className="img-fluid w-100" />
          <h1 className="waysToPay-hero-heading text-start">Career</h1>
        </div>
      </div>

      <div className="waysToPay-five-container container mt-5">
        <h1 className="mb-5 billing-h1">
          Building a Culture of Excellence and Opportunity
        </h1>
        <div className="row">
        <div className="col-md-5 py-sm-0 px-sm-2 mt-sm-4 mt-md-0">
  <div className="card h-100 text-center">
    <div
      className="card-body px-3"
      style={{
        backgroundColor: "rgb(216, 229, 240)",
        borderRadius: "10px",
      }}
    >
      <h1 className="mt-2 text-center ">
        <img
          src={CardIcon1}
          alt="Energy Savings Tips and Tools"
          className="img-fluid"
          style={{
            width: "100px",
            height: "100px",
            objectFit: "contain",
          }}
        />
      </h1>
      <h4 className="card-text  mive mt-md-3">
      A Workplace Driven by Safety, Service, and Community
      </h4>
      <p className=" mt-sm-1 mt-md-2 mt-lg-4">
      Our employees have created a culture that involves safety,
                  service, community, diversity, and innovation. Learn more
                  about career opportunities and programs.

      </p>
      <div className="d-flex justify-content-center mb-4 mt-sm-2 mt-md-2 mt-lg-3 mt-xl-3 Billing-btn">
       
          <div className="mt-auto">
            <a href="#" className="mt-3">
              Learn more
            </a>
          </div>
       
      </div>
    </div>
  </div>
</div>

<div className="col-md-7 mt-sm-5 mt-md-0 mt-4">
            <img
              src={CarrerCardImg1}
              alt="assistance progress"
              className="manageHiBill-img img-fluid border border-md border-primary rounded"
            />
          </div>
        </div>
      </div>


      <div className="waysToPay-four-container container assist-container mt-5">
        <h1 className="mb-5 billing-h1">Pathways to Professional Growth</h1>
        <div className="row assistance-prog">
        <div className="col-md-7">
            <img
              src={CarrerCardImg2}
              alt="assistance progress"
              className="assist-prog-img img-fluid border border-md border-primary rounded"
            />
          </div>

          <div className="col-md-5 py-sm-0 px-sm-2 mt-sm-4 mt-md-0  mt-sm-5 mt-md-0 mt-4">
            <div className="card h-100 text-center">
              <div
                className="card-body px-3"
                style={{
                  backgroundColor: "rgb(216, 229, 240)",
                  borderRadius: "10px",
                }}
              >
                <div className="text-center">
                  <img
                    src={CardIcon2}
                    alt="Energy Savings Tips and Tools"
                    className="img-fluid mt-4"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <h4 className="card-text  mt-sm-0 mt-md-3">
                Hands-On Experience and Career Opportunities

                </h4>
                <p className=" mt-sm-1 mt-md-2 mt-lg-2">
                Inspiring future generations of talented individuals who will
                  improve energy systems is one way we shape the future. Our
                  internship programs offer rich work experiences, rewarding
                  peer-to-peer connections, and great networking opportunities

                </p>
                <div className="d-flex justify-content-center mt-sm-1 mb-4 mt-md-1 mt-lg-3 mt-xl-3 Billing-btn">
                  
                    <div className="mt-auto">
                      <a href="#" className="">
                        Learn more
                      </a>
                    </div>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="careers-page">
        {/* Banner Section */}

        {/* Career Info Section */}
       

        <Testimonials />

        {/* FAQ Section */}
        <div className="container">
          
          <div className="row">
          <h1 className="heading-h1 mt-5">Frequently Asked Questions</h1>
           <div className="col mt-4">
           {faqData.map((faq, index) => (
          <div
            className={`waysToPay-faq-item ${
              activeIndex === index ? "active" : ""
            }`}
            key={index}
          >
            <button
              className="waysToPay-faq-question"
              onClick={() => handleClick(index)}
            >
              {faq.question}
              <span className="waysToPay-faq-icon">
                {activeIndex === index ? "-" : "+"}
              </span>
            </button>
            <div
              className={`waysToPay-faq-answer ${
                activeIndex === index ? "show" : ""
              }`}
            >
              {faq.answer}
            </div>
          </div>
        ))}
           </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Careers;
