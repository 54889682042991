import React from "react";
import "../pages/css/app.css";
import SoCalGasLogo from "../assets/images/scg-logo-white.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer py-4 mt-5">
      <div className="container  footer-container">
        {/* Company Name and Social Media Links */}
        <div className="row mb-4">
          <div className="col-12">
            <div className="footer-logo-section">
              <img
                src={SoCalGasLogo}
                alt="SoCalGas Logo"
                className="footer-logo"
              />
              <p className="footer-description">
                Our mission is to build the cleanest, safest, and most
                innovative energy infrastructure company in America.
              </p>

              {/* Social Media Icons */}
              <div className="footer-social mb-4">
                <a href="#">
                  <i className="fab fa-linkedin"></i>
                </a>
                <a href="#">
                  <i className="fab fa-facebook"></i>
                </a>
                <a href="#">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="#">
                  <i className="fab fa-x-twitter"></i>
                </a>
                <a href="#">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Quick Navigation Links */}
        <div className="row">
          <div className="col-6 col-md-4 col-lg">
            <h4>Services</h4>
            <ul className="nav-links list-unstyled lh-lg">
              <Link to="/service-management">
                {" "}
                <li className="">Residential Services</li>
              </Link>
              <li>
                <Link to="/commingSoon"><a href="/commingSoon">Business Services</a></Link>
              </li>
              <li>
                <a href="#">Start Service</a>
              </li>
              <li>
                <a href="#">Stop Service</a>
              </li>
              <li>
                <a href="#">Transfer Service</a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-4 col-lg">
            <h4>Billing</h4>
            <ul className="nav-links list-unstyled lh-lg">
              <li>
                {" "}
                <Link to={"/ways-To-Pay"}>
                  <a href="/ways-To-Pay">Ways to Pay</a>
                </Link>
              </li>

              <li>
                {" "}
                <Link to="/assistance-Program">
                  {" "}
                  <a href="/assistance-Program">Assistance Programs</a>
                </Link>
              </li>

              <li>
                {" "}
                <Link to="/managehigh-Bill">
                  {" "}
                  <a href="/managehigh-Bill">Manage High Bills</a>
                </Link>
              </li>

              <li>
                {" "}
                <Link to="/understand-MyBill">
                  {" "}
                  <a href="/understand-MyBill">Understanding My Bill</a>
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/business-Billing">
                  {" "}
                  <a href="/business-Billing">Business Billing</a>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-4 col-lg mt-sm-3 mt-md-0">
            <h4>Savings</h4>
            <ul className="nav-links list-unstyled lh-lg">
              <li>
                <a href="/commingSoon">Rebates and Incentives</a>
              </li>
              <li>
                <a href="/commingSoon">Energy Saving Tips & Tools</a>
              </li>
              <li>
                <a href="/commingSoon">Business Savings</a>
              </li>
              <li>
                <a href="/commingSoon">Business Rebates</a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-4 col-lg mt-sm-3 mt-md-0">
            <h4>Safety</h4>
            <ul className="nav-links list-unstyled lh-lg">
              <li>
                <a href="/commingSoon">Emergency Resources</a>
              </li>
              <li>
                <a href="/commingSoon">Safety & Prevention</a>
              </li>
              <li>
                <a href="/commingSoon">Safety Resources</a>
              </li>
              <li>
                <a href="/commingSoon">Methane Emissions</a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-4 col-lg mt-sm-3 mt-md-0">
            <h4>About Us</h4>
            <ul className="nav-links list-unstyled lh-lg">
              <li>
               <Link to="/company-overview"> <a href="/company-overview">Our Mission</a></Link>
              </li>
              <li>
             <Link to="/careers">   <a href="/careers">Careers</a></Link>
              </li>
              <li>
                <a href="#News_section">Newsroom</a>
              </li>
              <li>
              <Link to="/contact-us"> <a href="/contact-us">Contact Us</a></Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom mb-0">
        <p>&copy; 2024 Southern California Gas Company. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
