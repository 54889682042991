import { useState, useRef } from "react";
import "./MarketPlacePg.css";
import LogoImg from "./assets/images/SocalGasMarketPlaceLogo.jpg";
import ShoppingCartIcon from "./assets/images/ShoppingCardicon.svg";
import Listicon from "./assets/images/collapseListicon.svg";
import Testimg1 from "./assets/images/carousalImg1.png";
import Testimg2 from "./assets/images/carousalImg2.png";
import Testimg3 from "./assets/images/carousalImg3.png";
import Testimg4 from "./assets/images/carousalImg4.png";

import waterHeatericon from "./assets/images/waterHeatericon.svg";
import ThermoStatsicon from "./assets/images/Thermostatsicon.svg";
import Rangeicon from "./assets/images/RangeOvenicon.svg";
import GasDrayericon from "./assets/images/GasDrayer.svg";
import Washericon from "./assets/images/Washersicon.svg";

import PaymentOptionImg from "./assets/images/paymentCardImg.svg";
import EnerveScoreImg from "./assets/images/EnerveScoreImg.svg";
import ReviewImg from "./assets/images/ReviewCardImg.svg";
import EcoFinancingImg from "./assets/images/Eco-financingImg.svg";

import ScoreBadges98 from "./assets/images/ScoreBadge98.svg";
import ScoreBadges95 from "./assets/images/ScoreBadge95.svg";
import ScoreBadges92 from "./assets/images/ScoreBadge92.png";

import R1TopProduct1 from "./assets/images/R1topmodelProductimg1.jpeg";
import R1TopProduct2 from "./assets/images/R1topmodelProductimg2.jpeg";
import R1TopProduct3 from "./assets/images/R1topmodelProductimg3.jpeg";
import R1TopProduct4 from "./assets/images/R1topmodelProductimg4.jpeg";
import R1TopProduct5 from "./assets/images/R1topmodelProductimg5.jpeg";
import R1TopProduct6 from "./assets/images/R1topmodelProductimg6.jpeg";

import R2TopProduct1 from "./assets/images/R2topmodelProductimg1.webp";
import R2TopProduct2 from "./assets/images/R2topmodelProductimg2.jpeg";
import R2TopProduct3 from "./assets/images/R2topmodelProductimg3.jpeg";
import R2TopProduct4 from "./assets/images/R2topmodelProductimg4.jpeg";
import R2TopProduct5 from "./assets/images/R2topmodelProductimg5.jpeg";
import R2TopProduct6 from "./assets/images/R2topmodelProductimg6.jpeg";

import R3TopProduct1 from "./assets/images/R3topmodelProductimg1.jpeg";
import R3TopProduct2 from "./assets/images/R3topmodelProductimg2.jpeg";
import R3TopProduct3 from "./assets/images/R3topmodelProductimg3.jpeg";
import R3TopProduct4 from "./assets/images/R3topmodelProductimg4.jpeg";
import R3TopProduct5 from "./assets/images/R3topmodelProductimg5.jpeg";
import R3TopProduct6 from "./assets/images/R3topmodelProductimg6.jpeg";
import R3TopProduct7 from "./assets/images/R3topmodelProductimg7.jpeg";
import R3TopProduct8 from "./assets/images/R3topmodelProductimg8.jpeg";

const MarketPlacePg = () => {

    const [isVisible, setIsVisible] = useState(false);
    const [isOtherSitesOpen, setIsOtherSitesOpen] = useState(false);
    const [isGetHelpOpen, setIsGetHelpOpen] = useState(false);
    const [isLegalOpen, setIsLegalOpen] = useState(false);

    const carouselRef1 = useRef(null);
    const carouselRef2 = useRef(null);
    const carouselRef3 = useRef(null);

    // Carousel next and previous handlers
    const handlePrev1 = () => {
        carouselRef1.current?.scrollBy({
            left: -carouselRef3.current.offsetWidth,
            behavior: "smooth",
        });
    };

    const handleNext1 = () => {
        carouselRef1.current?.scrollBy({
            left: carouselRef3.current.offsetWidth,
            behavior: "smooth",
        });
    };

    const handlePrev2 = () => {
        carouselRef2.current?.scrollBy({
            left: -carouselRef3.current.offsetWidth,
            behavior: "smooth",
        });
    };

    const handleNext2 = () => {
        carouselRef2.current?.scrollBy({
            left: carouselRef3.current.offsetWidth,
            behavior: "smooth",
        });
    };

    const handleNext3 = () => {
        carouselRef3.current?.scrollBy({
            left: -carouselRef3.current.offsetWidth,
            behavior: "smooth",
        });
    };

    const handlePrev3 = () => {
        carouselRef3.current?.scrollBy({
            left: carouselRef3.current.offsetWidth,
            behavior: "smooth",
        });
    };


    // Toggle the sidebar visibility
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    // Toggle "Our Other Sites" submenu
    const toggleOtherSites = () => {
        setIsOtherSitesOpen(!isOtherSitesOpen);
    };

    // Toggle "Get Help" submenu
    const toggleGetHelp = () => {
        setIsGetHelpOpen(!isGetHelpOpen);
    };

    // Toggle "Legal" submenu (third dropdown)
    const toggleLegal = () => {
        setIsLegalOpen(!isLegalOpen);
    };


    const handleDotClick = (e) => {
        // Remove 'active' class from all dots
        document.querySelectorAll(".carousel-dot").forEach((dot) => {
            dot.classList.remove("active");
        });

        // Add 'active' class to the clicked dot
        e.target.classList.add("active");
    };

    return (
        <>
            {/* -------------------------------------------marketPlace-first-container Header ------------------------------------------------- */}

            <div
                className="marketPlace-first-container container-fluid"
                style={{ background: "rgb(0, 117, 188)" }}
            >
                <div className="row align-items-center">
                    {/* Hero image always visible */}
                    <div className="col-8 d-flex justify-content-between align-items-center">
                        <div className="heroImg py-2">
                            <img
                                src={LogoImg}
                                alt="SoCalGas Logo"
                                style={{ width: "50%", height: "50%" }}
                                className="img-fluid"
                            />
                        </div>
                    </div>

                    {/* Right side part with buttons and icons */}
                    <div className="col-4 py-2 d-flex justify-content-around align-items-center">
                        {/* Buttons and icons */}
                        <button
                            className={`btn btn-success energy-saving-btn btn-sm p-2 fw-bold ${isVisible ? "d-block" : "d-none d-sm-block"}`}
                        >
                            Energy Saving Tips
                        </button>

                        <button
                            className={`btn btn-outline-light btn-sm px-3 fw-bold ${isVisible ? "d-block" : "d-none d-sm-block"}`}
                        >
                            Sign In
                        </button>
                        <img
                            src={ShoppingCartIcon}
                            alt="Shopping Cart Icon"
                            className={`${isVisible ? "d-block" : "d-none d-sm-block"}`}
                            style={{ width: "30px", height: "auto" }}
                        />

                        {/* List Icon always visible */}
                        <img
                            src={Listicon}
                            alt="List Icon"
                            onClick={toggleVisibility}
                            className="hamburger"
                        />
                    </div>
                </div>
            </div>

            {/* Sidebar Navigation */}
            <div className={`navigation ${isVisible ? 'active' : ''}`} id="navigation">

                <div className="nav-items">
                    <ul>
                        <li className="navigation-section">
                            <h3 className="navigation-title">
                                Navigation
                            </h3>
                            <span className="close-icon" onClick={toggleVisibility}>✖</span>
                        </li>
                        <li>
                            <div>  <button className="nav-first-button"> Sign In </button>  </div>
                            <div>   <button className="nav-second-button"> Energy Saving Tips </button>  </div>
                        </li>

                        <li> Privacy Policy </li>

                        {/* "Our Other Sites" with submenu */}
                        <li onClick={toggleOtherSites} className="submenu-header">
                        <i className="fas fa-ellipsis-h"></i> {/* Three dots icon */}
                        &nbsp;<span>Our Other Sites</span>
                            <i className={`fas fa-chevron-down arrow ${isOtherSitesOpen ? 'rotate' : ''}`}></i> {/* Dropdown icon */}
                        </li>
                        {isOtherSitesOpen && (
                            <ul className="submenu">
                                <li> My Account</li>
                                <li> SoCalGas Newsroom</li>
                                <li> Safety & Emergencies</li>
                            </ul>
                        )}

                        <li> Find Rebates</li>
                        <li> SoCalGas Home Page</li>

                        {/* "Get Help" with submenu */}
                        <li onClick={toggleGetHelp} className="submenu-header">
                        <i class="fa-light fa-circle-question"></i> &nbsp;<span>Get Help</span>
                            <i className={`fas fa-chevron-down second-arrow ${isGetHelpOpen ? 'rotate' : ''}`}></i> {/* Dropdown icon */}
                        </li>
                        {isGetHelpOpen && (
                            <ul className="submenu">
                                <li> Benefits of the Marketplace</li>
                                <li> About Us</li>
                                <li> Support</li>
                                <li> Contact Us</li>
                            </ul>
                        )}

                        {/* "Legal" submenu (third dropdown) */}
                        <li onClick={toggleLegal} className="submenu-header">
                        <i class="fa-light fa-book-blank"></i> &nbsp;<span>Legal</span>
                            <i className={`fas fa-chevron-down third-arrow ${isLegalOpen ? 'rotate' : ''}`}></i> {/* Dropdown icon */}
                        </li>
                        {isLegalOpen && (
                            <ul className="submenu">
                                <li>Privacy Policy</li>
                                <li>Terms and Conditions</li>
                            </ul>
                        )}
                    </ul>
                </div>
            </div>



            {/*---------------------------------------------------MarketPlace-second-container----------------------------------------------------- */}

            <div className="marketPlace-second-container container mt-sm-3">
                <row className="">
                    <div className="col d-flex justify-content-end mt-sm-3">

                        <a
                            style={{ textDecoration: "none" }}
                            href="https://fast.wistia.net/embed/iframe/ufspkthdiv?seo=false&videoFoam=true"
                        >
                            <button className="btn btn-watch-vdo btn-outline-primary btn-sm px-4 fw-bold ">                                  <i className="fa-regular fa-circle-play fa-beat"></i>
                                &nbsp;Watch Our Video

                            </button>
                        </a>
                    </div>
                </row>

                <div className="row mt-3">
                    <h1 className="text-primary">
                        SoCalGass<sup>®️</sup>Marketplace
                    </h1>
                    <h5 className="fw-bold">
                        Save Energy. Save Money.<sup>1</sup>
                    </h5>
                </div>

                {/* -------------------carousal image----------------------  */}

                <div className="row mt-4 carousel-section-row">
                    <div className="col">
                        <div
                            id="carouselExampleCaptions"
                            className="carousel slide custom-carousel"
                            data-bs-ride="carousel"
                            data-bs-interval="3000" // 3 seconds auto slide
                        >
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img
                                        src={Testimg1}
                                        className="d-block w-100 custom-carousel-img"
                                        alt="First slide"
                                    />
                                </div>
                                <div className="carousel-item">
                                    <img
                                        src={Testimg2}
                                        className="d-block w-100 custom-carousel-img"
                                        alt="Second slide"
                                    />
                                </div>
                                <div className="carousel-item">
                                    <img
                                        src={Testimg3}
                                        className="d-block w-100 custom-carousel-img"
                                        alt="Third slide"
                                    />
                                </div>
                                <div className="carousel-item">
                                    <img
                                        src={Testimg4}
                                        className="d-block w-100 custom-carousel-img"
                                        alt="Fourth slide"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Custom Dots for carousel navigation */}
                        <div className="carousel-dots mt-2 text-center">
                            <input
                                type="radio"
                                name="carousel-dot"
                                id="dot1"
                                data-bs-target="#carouselExampleCaptions"
                                data-bs-slide-to="0"
                                className="carousel-dot"
                                onClick={handleDotClick}
                            />
                            <input
                                type="radio"
                                name="carousel-dot"
                                id="dot2"
                                data-bs-target="#carouselExampleCaptions"
                                data-bs-slide-to="1"
                                className="carousel-dot"
                                onClick={handleDotClick}
                            />
                            <input
                                type="radio"
                                name="carousel-dot"
                                id="dot3"
                                data-bs-target="#carouselExampleCaptions"
                                data-bs-slide-to="2"
                                className="carousel-dot"
                                onClick={handleDotClick}
                            />
                            <input
                                type="radio"
                                name="carousel-dot"
                                id="dot4"
                                data-bs-target="#carouselExampleCaptions"
                                data-bs-slide-to="3"
                                className="carousel-dot"
                                onClick={handleDotClick}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/*-------------------------------------------marketPlace-third-conainer-----------------------------------------------------------------*/}
            <div className="marketPlace-third-container container mt-5">
                <div className="row mt-5">
                    <div className="input-group mb-3 rounded">
                        <input
                            type="text"
                            className="form-control py-2"
                            placeholder="Search..."
                            aria-label="Search"
                            aria-describedby="basic-addon2"
                            value=""
                        />{" "}
                        &nbsp;
                        <span className=" border border-secondary pt-2 px-3 rounded">
                            {" "}
                            <i className="fa-solid fa-magnifying-glass"></i>
                        </span>
                    </div>
                </div>

                {/* Products icons   */}

                <div className="row mt-2">
                    <div className="col-sm-6 col-md-4 text-center d-flex flex-column align-items-center">
                        <div className="producticon">
                            <img
                                src={waterHeatericon}
                                alt="Gas Water Heaters"
                                className="product-image"
                            />
                        </div>
                        <span>Gas Water Heaters</span>
                        <div className="productsubicon mb-4 d-flex justify-content-around mt-1">
                            <i className="fa-solid fa-sack-dollar text-success"></i>
                            <i className="fa-solid fa-cart-shopping text-primary"></i>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 text-center d-flex flex-column align-items-center">
                        <div className="producticon">
                            <img
                                src={ThermoStatsicon}
                                alt="ThermoStats"
                                className="product-image"
                            />
                        </div>
                        <span>ThermoStats</span>
                        <div className="productsubicon mb-4 d-flex justify-content-around mt-1">
                            <i className="fa-solid fa-sack-dollar text-success"></i>
                            <i className="fa-solid fa-cart-shopping text-primary"></i>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 text-center d-flex flex-column align-items-center">
                        <div className="producticon">
                            <img src={Rangeicon} alt="Ranges" className="product-image" />
                        </div>
                        <span>Ranges</span>
                        <div className="productsubicon mb-4 d-flex justify-content-around mt-1">
                            <i className="fa-solid fa-sack-dollar text-success"></i>
                            <i className="fa-solid fa-cart-shopping text-primary"></i>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 text-center d-flex flex-column align-items-center mt-3">
                        <div className="producticon">
                            <img
                                src={GasDrayericon}
                                alt="Gas Dryers"
                                className="product-image"
                            />
                        </div>
                        <span>Gas Dryers</span>
                        <div className="productsubicon mb-4 d-flex justify-content-around mt-1">
                            <i className="fa-solid fa-sack-dollar text-success"></i>
                            <i className="fa-solid fa-cart-shopping text-primary"></i>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 text-center d-flex flex-column align-items-center mt-3">
                        <div className="producticon">
                            <img src={Washericon} alt="Washers" className="product-image" />
                        </div>
                        <span>Washers</span>
                        <div className="productsubicon d-flex justify-content-around mt-1">
                            <i className="fa-solid fa-cart-shopping text-primary"></i>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-4 text-center d-flex flex-column align-items-center mt-3">
                        <div className="producticon">
                            <i
                                className="fa-solid fa-ellipsis"
                                style={{ fontSize: "60px" }}
                            ></i>
                        </div>
                        <span>Exploring More Categories</span>
                    </div>
                </div>
            </div>

            {/*------------------------------------------------------marketPlace-four-container------------------------------------------------------*/}

            <div className="marketPlace-four-container container mt-5">
                <div className="row mt-5 product-card">
                    <h2 className="text-primary fw-bold text-center mb-5">
                        We help you find your perfect product
                    </h2>

                    {/* Container for the Cards */}
                    <div className="row text-center">
                        {/* Card 1 */}
                        <div className="col-12 col-sm-6 col-md-4 mb-4">
                            <div className="card shadow-sm hover-effect small-card">
                                <img
                                    src={PaymentOptionImg}
                                    className="card-img-top small-img"
                                    alt="Card 1"
                                />
                                <div className="card-body">
                                    <h5 className="card-title">Payment Options</h5>
                                    <p className="card-text">
                                        Buy your appliance with Eco Financing and $0 down, or with
                                        any one of our accepted payment types.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Card 2 */}
                        <div className="col-12 col-sm-6 col-md-4 mb-4">
                            <div className="card h-100 shadow-sm hover-effect small-card">
                                <img
                                    src={EnerveScoreImg}
                                    className="card-img-top small-img"
                                    alt="Card 2"
                                />
                                <div className="card-body">
                                    <h5 className="card-title">Enervee Score</h5>
                                    <p className="card-text">
                                        A high score means more energy savings and could result in a
                                        lower utility bill.
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Card 3 */}
                        <div className="col-12 col-sm-6 col-md-4 mb-4">
                            <div className="card h-100 shadow-sm hover-effect small-card">
                                <img
                                    src={ReviewImg}
                                    className="card-img-top small-img"
                                    alt="Card 3"
                                />
                                <div className="card-body">
                                    <h5 className="card-title">All reviews in one place</h5>
                                    <p className="card-text">
                                        View both customer and retailer reviews to help you with
                                        your product selection.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*-----------------------------------------------marketPlace-five-container Yt-player-------------------------------------------------------*/}

            <div className="marketPlace-Five-container mt-5 container">
                {/* YT player */}
                <div className="row">
                    <div className="col-12">
                        <div
                            className="video-container position-relative"
                            style={{
                                overflow: "hidden",
                                paddingTop: "56.25%", // This keeps a 16:9 aspect ratio
                            }}
                        >
                            <iframe
                                src="https://fast.wistia.net/embed/iframe/ufspkthdiv?seo=false&videoFoam=true"
                                title="Eco Financing - appliance shopping made easy (SCG) Video"
                                allow="autoplay; fullscreen"
                                frameBorder={0}
                                scrolling="no"
                                className="wistia_embed position-absolute top-0 start-0 w-100 h-100"
                                style={{ borderRadius: "10px" }} // Reduced border radius for iframe as well
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/*--------------------------------------------------marketPlace-six-container top-model-products------------------------------------------------*/}

            <div className="marketPlace-six-container container mt-5">
                {/* first Carousal */}

                <div className="row mb-5 mt-5">
                    <h2 className="mt-5 mb-5 text-center text-primary">
                        Check out top models in popular categories
                    </h2>
                    <div className="col-12">
                        <div className="d-flex align-items-center justify-content-between">
                            {/* Carousel Wrapper */}
                            <div
                                className="carousel-wrapper d-flex overflow-auto"
                                ref={carouselRef1}
                                style={{ scrollSnapType: "x mandatory", gap: "10px" }}
                            >
                                {/* Card 1 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={R1TopProduct1}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div className="price-notification d-flex justify-content-between">
                                                            <span>
                                                                <i className="fa fa-bell text-success notification-icon p-1"></i>
                                                            </span>
                                                            <p className="price">$99.99</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">See All Offer</p>
                                </div>

                                {/* Card 2 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={R1TopProduct2}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div className="price-notification d-flex justify-content-between">
                                                            <span>
                                                                <i className="fa fa-bell text-success notification-icon p-1"></i>
                                                            </span>
                                                            <p className="price">$99.99</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">See All Offer</p>
                                </div>

                                {/* Card 3 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={R1TopProduct3}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div className="price-notification d-flex justify-content-between">
                                                            <span>
                                                                <i className="fa fa-bell text-success notification-icon p-1"></i>
                                                            </span>
                                                            <p className="price">$99.99</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">See All Offer</p>
                                </div>

                                {/* Card 4 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={R1TopProduct4}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div className="price-notification d-flex justify-content-between">
                                                            <span>
                                                                <i className="fa fa-bell text-success notification-icon p-1"></i>
                                                            </span>
                                                            <p className="price">$99.99</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">See All Offer</p>
                                </div>

                                {/* Card 5 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={R1TopProduct5}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div className="price-notification d-flex justify-content-between">
                                                            <span>
                                                                <i className="fa fa-bell text-success notification-icon p-1"></i>
                                                            </span>
                                                            <p className="price">$99.99</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">See All Offer</p>
                                </div>

                                {/* Card 6 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={R1TopProduct6}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div className="price-notification d-flex justify-content-between">
                                                            <span>
                                                                <i className="fa fa-bell text-success notification-icon p-1"></i>
                                                            </span>
                                                            <p className="price">$99.99</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">See All Offer</p>
                                </div>
                                {/* Card 7 */}

                                {/* Card 8 */}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mt-5">
                        {/* Previous Button */}
                        <i
                            className="fa fa-chevron-left"
                            onClick={handlePrev1}
                            style={{
                                cursor: "pointer",
                                fontSize: "24px",
                                marginRight: "10px",
                            }}
                        ></i>

                        {/* View All Button */}
                        <button className="btn btn-outline-primary btn-sm  px-sm-1 px-lg-4 ">
                            View All Discounted Ranges
                        </button>

                        {/* Next Button */}
                        <i
                            className="fa fa-chevron-right"
                            onClick={handleNext1}
                            style={{
                                cursor: "pointer",
                                fontSize: "24px",
                                marginLeft: "10px",
                            }}
                        ></i>
                    </div>
                </div>

                {/* second Carousal */}

                <div className="row mb-5">
                    <div className="col-12">
                        <div className="d-flex align-items-center justify-content-between">
                            {/* Carousel Wrapper */}
                            <div
                                className="carousel-wrapper d-flex overflow-auto"
                                ref={carouselRef2}
                                style={{ scrollSnapType: "x mandatory", gap: "10px" }}
                            >
                                {/* Card 1 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={ScoreBadges92}
                                                    alt="Score Badge"
                                                    className="badge-img position-absolute"
                                                />
                                                <img
                                                    src={R2TopProduct1}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div
                                                            className="price-offer"
                                                            style={{ textAlign: "right" }}
                                                        >
                                                            <span className="text-center">
                                                                <img
                                                                    src={EcoFinancingImg}
                                                                    alt=""
                                                                    style={{ width: "30px", height: "25px" }}
                                                                />
                                                            </span>
                                                            <span className="fw-bold">
                                                                {" "}
                                                                &nbsp;$23.0/Mo<sup>*</sup>
                                                            </span>
                                                            <p>
                                                                or <del>$45218</del> $32545
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">
                                        <sup>*</sup>60 mo 9.99% APR
                                    </p>
                                </div>

                                {/* Card 2 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={ScoreBadges92}
                                                    alt="Score Badge"
                                                    className="badge-img position-absolute"
                                                />
                                                <img
                                                    src={R2TopProduct2}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div
                                                            className="price-offer"
                                                            style={{ textAlign: "right" }}
                                                        >
                                                            <span className="text-center">
                                                                <img
                                                                    src={EcoFinancingImg}
                                                                    alt=""
                                                                    style={{ width: "30px", height: "25px" }}
                                                                />
                                                            </span>
                                                            <span className="fw-bold">
                                                                {" "}
                                                                &nbsp;$23.0/Mo<sup>*</sup>
                                                            </span>
                                                            <p>
                                                                or <del>$45218</del> $32545
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">
                                        <sup>*</sup>60 mo 9.99% APR
                                    </p>
                                </div>

                                {/* Card 3 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={ScoreBadges92}
                                                    alt="Score Badge"
                                                    className="badge-img position-absolute"
                                                />
                                                <img
                                                    src={R2TopProduct3}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div
                                                            className="price-offer"
                                                            style={{ textAlign: "right" }}
                                                        >
                                                            <span className="text-center">
                                                                <img
                                                                    src={EcoFinancingImg}
                                                                    alt=""
                                                                    style={{ width: "30px", height: "25px" }}
                                                                />
                                                            </span>
                                                            <span className="fw-bold">
                                                                {" "}
                                                                &nbsp;$23.0/Mo<sup>*</sup>
                                                            </span>
                                                            <p>
                                                                or <del>$45218</del> $32545
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">
                                        <sup>*</sup>60 mo 9.99% APR
                                    </p>
                                </div>

                                {/* Card 4 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={ScoreBadges95}
                                                    alt="Score Badge"
                                                    className="badge-img position-absolute"
                                                />
                                                <img
                                                    src={R2TopProduct4}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div
                                                            className="price-offer"
                                                            style={{ textAlign: "right" }}
                                                        >
                                                            <span className="text-center">
                                                                <img
                                                                    src={EcoFinancingImg}
                                                                    alt=""
                                                                    style={{ width: "30px", height: "25px" }}
                                                                />
                                                            </span>
                                                            <span className="fw-bold">
                                                                {" "}
                                                                &nbsp;$23.0/Mo<sup>*</sup>
                                                            </span>
                                                            <p>
                                                                or <del>$45218</del> $32545
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">
                                        <sup>*</sup>60 mo 9.99% APR
                                    </p>
                                </div>

                                {/* Card 5 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={ScoreBadges95}
                                                    alt="Score Badge"
                                                    className="badge-img position-absolute"
                                                />
                                                <img
                                                    src={R2TopProduct5}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div
                                                            className="price-offer"
                                                            style={{ textAlign: "right" }}
                                                        >
                                                            <span className="text-center">
                                                                <img
                                                                    src={EcoFinancingImg}
                                                                    alt=""
                                                                    style={{ width: "30px", height: "25px" }}
                                                                />
                                                            </span>
                                                            <span className="fw-bold">
                                                                {" "}
                                                                &nbsp;$23.0/Mo<sup>*</sup>
                                                            </span>
                                                            <p>
                                                                or <del>$45218</del> $32545
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">
                                        <sup>*</sup>60 mo 9.99% APR
                                    </p>
                                </div>

                                {/* Card 6 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={ScoreBadges98}
                                                    alt="Score Badge"
                                                    className="badge-img position-absolute"
                                                />
                                                <img
                                                    src={R2TopProduct6}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div
                                                            className="price-offer"
                                                            style={{ textAlign: "right" }}
                                                        >
                                                            <span className="text-center">
                                                                <img
                                                                    src={EcoFinancingImg}
                                                                    alt=""
                                                                    style={{ width: "30px", height: "25px" }}
                                                                />
                                                            </span>
                                                            <span className="fw-bold">
                                                                {" "}
                                                                &nbsp;$23.0/Mo<sup>*</sup>
                                                            </span>
                                                            <p>
                                                                or <del>$45218</del> $32545
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">
                                        <sup>*</sup>60 mo 9.99% APR
                                    </p>
                                </div>
                                {/* Card 7 */}

                                {/* Card 8 */}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mt-5">
                        {/* Previous Button */}
                        <i
                            className="fa fa-chevron-left"
                            onClick={handlePrev2}
                            style={{
                                cursor: "pointer",
                                fontSize: "24px",
                                marginRight: "10px",
                            }}
                        ></i>

                        {/* View All Button */}
                        <button className="btn btn-outline-primary btn-sm  px-2">
                            View All Energy Efficient Gas Drayer
                        </button>

                        {/* Next Button */}
                        <i
                            className="fa fa-chevron-right"
                            onClick={handleNext2}
                            style={{
                                cursor: "pointer",
                                fontSize: "24px",
                                marginLeft: "10px",
                            }}
                        ></i>
                    </div>
                </div>

                {/* Third-carousal */}

                <div className="row mb-5">
                    <div className="col-12">
                        <div className="d-flex align-items-center justify-content-between">
                            {/* Carousel Wrapper */}
                            <div
                                className="carousel-wrapper d-flex overflow-auto"
                                ref={carouselRef3}
                                style={{ scrollSnapType: "x mandatory", gap: "10px" }}
                            >
                                {/* Card 1 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={ScoreBadges92}
                                                    alt="Score Badge"
                                                    className="badge-img position-absolute"
                                                />
                                                <img
                                                    src={R3TopProduct1}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div
                                                            className="price-offer"
                                                            style={{ textAlign: "right" }}
                                                        >
                                                            <span className="text-center">
                                                                <img
                                                                    src={EcoFinancingImg}
                                                                    alt=""
                                                                    style={{ width: "30px", height: "25px" }}
                                                                />
                                                            </span>
                                                            <span className="fw-bold">
                                                                {" "}
                                                                &nbsp;$23.0/Mo<sup>*</sup>
                                                            </span>
                                                            <p>
                                                                or <del>$45218</del> $32545
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">
                                        <sup>*</sup>60 mo 9.99% APR
                                    </p>
                                </div>

                                {/* Card 2 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={ScoreBadges92}
                                                    alt="Score Badge"
                                                    className="badge-img position-absolute"
                                                />
                                                <img
                                                    src={R3TopProduct2}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div
                                                            className="price-offer"
                                                            style={{ textAlign: "right" }}
                                                        >
                                                            <span className="text-center">
                                                                <img
                                                                    src={EcoFinancingImg}
                                                                    alt=""
                                                                    style={{ width: "30px", height: "25px" }}
                                                                />
                                                            </span>
                                                            <span className="fw-bold">
                                                                {" "}
                                                                &nbsp;$23.0/Mo<sup>*</sup>
                                                            </span>
                                                            <p>
                                                                or <del>$45218</del> $32545
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">
                                        <sup>*</sup>60 mo 9.99% APR
                                    </p>
                                </div>

                                {/* Card 3 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={ScoreBadges92}
                                                    alt="Score Badge"
                                                    className="badge-img position-absolute"
                                                />
                                                <img
                                                    src={R3TopProduct3}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div
                                                            className="price-offer"
                                                            style={{ textAlign: "right" }}
                                                        >
                                                            <span className="text-center">
                                                                <img
                                                                    src={EcoFinancingImg}
                                                                    alt=""
                                                                    style={{ width: "30px", height: "25px" }}
                                                                />
                                                            </span>
                                                            <span className="fw-bold">
                                                                {" "}
                                                                &nbsp;$23.0/Mo<sup>*</sup>
                                                            </span>
                                                            <p>
                                                                or <del>$45218</del> $32545
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">
                                        <sup>*</sup>60 mo 9.99% APR
                                    </p>
                                </div>

                                {/* Card 4 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={ScoreBadges95}
                                                    alt="Score Badge"
                                                    className="badge-img position-absolute"
                                                />
                                                <img
                                                    src={R3TopProduct4}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div
                                                            className="price-offer"
                                                            style={{ textAlign: "right" }}
                                                        >
                                                            <span className="text-center">
                                                                <img
                                                                    src={EcoFinancingImg}
                                                                    alt=""
                                                                    style={{ width: "30px", height: "25px" }}
                                                                />
                                                            </span>
                                                            <span className="fw-bold">
                                                                {" "}
                                                                &nbsp;$23.0/Mo<sup>*</sup>
                                                            </span>
                                                            <p>
                                                                or <del>$45218</del> $32545
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">
                                        <sup>*</sup>60 mo 9.99% APR
                                    </p>
                                </div>

                                {/* Card 5 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={ScoreBadges95}
                                                    alt="Score Badge"
                                                    className="badge-img position-absolute"
                                                />
                                                <img
                                                    src={R3TopProduct5}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div
                                                            className="price-offer"
                                                            style={{ textAlign: "right" }}
                                                        >
                                                            <span className="text-center">
                                                                <img
                                                                    src={EcoFinancingImg}
                                                                    alt=""
                                                                    style={{ width: "30px", height: "25px" }}
                                                                />
                                                            </span>
                                                            <span className="fw-bold">
                                                                {" "}
                                                                &nbsp;$23.0/Mo<sup>*</sup>
                                                            </span>
                                                            <p>
                                                                or <del>$45218</del> $32545
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">
                                        <sup>*</sup>60 mo 9.99% APR
                                    </p>
                                </div>

                                {/* Card 6 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={ScoreBadges95}
                                                    alt="Score Badge"
                                                    className="badge-img position-absolute"
                                                />
                                                <img
                                                    src={R3TopProduct6}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div
                                                            className="price-offer"
                                                            style={{ textAlign: "right" }}
                                                        >
                                                            <span className="text-center">
                                                                <img
                                                                    src={EcoFinancingImg}
                                                                    alt=""
                                                                    style={{ width: "30px", height: "25px" }}
                                                                />
                                                            </span>
                                                            <span className="fw-bold">
                                                                {" "}
                                                                &nbsp;$23.0/Mo<sup>*</sup>
                                                            </span>
                                                            <p>
                                                                or <del>$45218</del> $32545
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">
                                        <sup>*</sup>60 mo 9.99% APR
                                    </p>
                                </div>

                                {/* Card 7 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={ScoreBadges98}
                                                    alt="Score Badge"
                                                    className="badge-img position-absolute"
                                                />
                                                <img
                                                    src={R3TopProduct7}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div
                                                            className="price-offer"
                                                            style={{ textAlign: "right" }}
                                                        >
                                                            <span className="text-center">
                                                                <img
                                                                    src={EcoFinancingImg}
                                                                    alt=""
                                                                    style={{ width: "30px", height: "25px" }}
                                                                />
                                                            </span>
                                                            <span className="fw-bold">
                                                                {" "}
                                                                &nbsp;$23.0/Mo<sup>*</sup>
                                                            </span>
                                                            <p>
                                                                or <del>$45218</del> $32545
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">
                                        <sup>*</sup>60 mo 9.99% APR
                                    </p>
                                </div>

                                {/* Card 8 */}
                                <div>
                                    <div
                                        className="card product-card"
                                        style={{ minWidth: "300px", scrollSnapAlign: "start" }}
                                    >
                                        <div className="row g-0">
                                            <div className="col-4 position-relative">
                                                <img
                                                    src={ScoreBadges98}
                                                    alt="Score Badge"
                                                    className="badge-img position-absolute"
                                                />
                                                <img
                                                    src={R3TopProduct8}
                                                    className="img-fluid px-3 mt-5 mb-1 product-img"
                                                    alt="Product 1"
                                                />
                                                <span
                                                    className="bg-success py-1 px-1 text-white"
                                                    style={{
                                                        borderTopRightRadius: "15px",
                                                        borderBottomRightRadius: "15px",
                                                    }}
                                                >
                                                    $100 rebate
                                                </span>
                                            </div>
                                            <div className="col-8">
                                                <div className="card border-0">
                                                    <div className="card-body border-start">
                                                        <h6 className="card-title fw-bold">
                                                            Amana-AGR6603SFB
                                                        </h6>
                                                        <span className="card-text ">
                                                            Amana 5 cu ft Grate Gas
                                                        </span>
                                                        <p className="rating">
                                                            ⭐⭐⭐⭐☆ &nbsp;
                                                            <span className="">(13)</span>
                                                        </p>
                                                        <hr />
                                                        <div
                                                            className="price-offer"
                                                            style={{ textAlign: "right" }}
                                                        >
                                                            <span className="text-center">
                                                                <img
                                                                    src={EcoFinancingImg}
                                                                    alt=""
                                                                    style={{ width: "30px", height: "25px" }}
                                                                />
                                                            </span>
                                                            <span className="fw-bold">
                                                                {" "}
                                                                &nbsp;$23.0/Mo<sup>*</sup>
                                                            </span>
                                                            <p>
                                                                or <del>$45218</del> $32545
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-primary offer-section">
                                        <sup>*</sup>60 mo 9.99% APR
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mt-5">
                        {/* Previous Button */}
                        <i
                            className="fa fa-chevron-left"
                            onClick={handleNext3}
                            style={{
                                cursor: "pointer",
                                fontSize: "24px",
                                marginRight: "10px",
                            }}
                        ></i>
                        {/* View All Button */}
                        <button className="btn btn-outline-primary btn-sm px-4">
                            View All Energy Efficient Washers
                        </button>

                        {/* Next Button */}
                        <i
                            className="fa fa-chevron-right"
                            onClick={handlePrev3}
                            style={{
                                cursor: "pointer",
                                fontSize: "24px",
                                marginLeft: "10px",
                            }}
                        ></i>
                    </div>
                </div>
            </div>

            {/* -----------------------------------------------------marketPlace-seven-container large information---------------------------------------- */}

            <div className="marketPlace-seven-container container mt-5">
                <div className="row mt-5 info-row">
                    <h6 className="text-primary">
                        Local Energy Efficiency Benefits and Opportunities | US EPA
                    </h6>

                    <p className="info-paragraph mt-3">
                        SoCalGas has contracted with Enervee Corporation
                        (&ldquo;Enervee&rdquo;) for Enervee to create, operate, manage, and
                        host the digital marketing program known as SoCalGas® Marketplace,
                        which enables integration of third-party advertisements, products
                        and services, such as home appliances, product warranties, installer
                        services (collectively, with Enervee, other persons referred in the
                        SoCalGas Marketplace, and their respective subsidiaries or its
                        affiliates, &quot;Third Parties&quot;). SoCalGas role is only
                        administrative in nature.
                    </p>

                    <p className="info-paragraph">
                        Other than described above, SoCalGas is not affiliated, associated,
                        authorized, or in any way officially connected with the Third
                        Parties, nor does it endorse or recommend the products or services
                        of the Third Parties, whether or not appearing on the SoCalGas
                        Marketplace. The Third Parties are not agents of SoCalGas, and
                        SoCalGas is not an agent of you or the Third Parties. The Third
                        Parties are solely responsible for their respective products and
                        services marketed or sold to you and any credit extended to you.
                        SoCalGas shall not be liable for any energy savings or payment
                        assumptions of any type arising out of, or in any way connected
                        with, your purchase of any such product or service or acceptance of
                        such credit. SoCalGas does not accept credit applications,
                        originate, service, make loans or installment contracts, or credit
                        decisions in connection with loans or installment contracts.
                        SoCalGas is not making an offer to lend and has no role in reviewing
                        or approving your financing application. SoCalGas shall not be
                        liable for any financing arrangement between you and your lender or
                        such lender’s acts or omissions. You should rely on your own
                        judgment in deciding which available credit product and terms best
                        suits your personal financial requirements.
                    </p>

                    <p className="info-paragraph">
                        *© Eco Financing as low as 9.99% Annual Percentage Rate (APR) is
                        effective as of 1/1/2023, is based on creditworthiness, underwriting
                        factors, and information obtained by third-party companies. It is
                        our best rate which includes an incentive rate discount for setting
                        up automatic sample monthly loan payments at loan origination and is
                        subject to loan approval. Sample monthly payment per $1,000 for 12
                        months at 9.99% is $87.91. A sample monthly payment for $1,000 for
                        60 months at 9.99% is $21.24. These payment examples are for
                        illustration purposes only. Your actual payment may vary. All Eco
                        Financing loan programs, rates, terms, and conditions are subject to
                        change without prior notice. Minimum Experian Vantage Score 4.0 of
                        580 to qualify. Other underwriting factors apply. Eco Financing by
                        Enervee loan programs are owned and funded by Lewis & Clark Bank,
                        Member FDIC.
                    </p>

                    <p className="info-paragraph">
                        †Applying will not impact your credit score. Once a loan is approved
                        and monies are received, it will, like all loans, impact your credit
                        score.
                    </p>

                    <p className="info-paragraph">
                        Eco Financing loan programs and offers are only valid for Southern
                        California Gas customers who are legal residents of the State of
                        California. Applicants must purchase a qualifying appliance through
                        the Enervee Marketplace. If utility customer and residency are not
                        validated or if a qualifying appliance is not purchased, program
                        offering is invalid. Financing is subject to loan approval.
                    </p>

                    <p className="info-paragraph">
                        This website is run through a 3rd party program and is not part of
                        the Southern California Gas Company. Terms and Conditions and
                        Privacy Policy for the Enervee Corporation will apply.
                    </p>

                    <p className="info-paragraph">
                        For Enervees return policy, click here. For Enervees shipping
                        policy, click here. To contact Enervee support, click here.
                    </p>
                </div>
            </div>

            {/* ---------------------------------------------------------marketPlace-eight-container footer------------------------------------------------- */}
            <div className="marketPlace-eight-container container mt-2">
                <div className="row">
                    <footer className="border border-light fixed-footer">
                        <div className="LHS-link">
                            <span>
                                ©️ 2024 &nbsp;<a href="">Enerve Corporation</a> &nbsp; | &nbsp;
                                <a href="">  Enerve Private Policy</a>
                            </span>
                        </div>

                        <div className="RHS-link">
                            <span>
                                <a href="">About Us</a> &nbsp; | &nbsp;<a href=""> Support</a>{" "}
                                &nbsp; | &nbsp; <a href=""> Contact Us</a> &nbsp; | &nbsp;
                                <a href=""> Terms and Condition</a>
                            </span>
                        </div>
                    </footer>
                </div>
            </div>

        </>
    );
};

export default MarketPlacePg;
