import React from 'react';
import './css/app.css'; 
import profile from '../assets/images/profile-img.png';
import Scott_Drury from '../assets/images/profile-img.png';
import maryamsbrown from '../assets/images/team_socal_images/maryamsbrown.png';
import Jimmie_Cho from '../assets/images/team_socal_images/jimmiecho.png';
import David_Barrett from '../assets/images/team_socal_images/davidbarrett.png';
import David_Buczkowski from '../assets/images/team_socal_images/davidbuczkowski.png';
import Andy_Carrasco from '../assets/images/team_socal_images/andycarrasco.png';
import Mia_DeMontigny from '../assets/images/team_socal_images/miademontigny.png';
import Paul_Goldstein from '../assets/images/team_socal_images/paulgoldstein.png';
import Ben_Gordon from '../assets/images/team_socal_images/bengordon.png';
import Sandra_Hrna from '../assets/images/team_socal_images/sandrahrna.png';
import Kent_Kauss from '../assets/images/team_socal_images/kentkauss.png';
import Jawaad_Malik from '../assets/images/team_socal_images/jawaadmalik.png';
import Sara_Mijares from '../assets/images/team_socal_images/saramijares.png';
import Mitch_Mitchell from '../assets/images/team_socal_images/mitchmitchell.png';
import Neil_Navin from '../assets/images/team_socal_images/neilnavin.png';
import Deana_Ng from '../assets/images/team_socal_images/deana-ng.png';
import Gina_Orozco from '../assets/images/team_socal_images/ginaorozco.png';
import Rodger_Schwecke from '../assets/images/team_socal_images/rodgerschwecke.png';
import Dan_Skopec from '../assets/images/team_socal_images/danskopec.png';
import Erin_Smith from '../assets/images/team_socal_images/erinmsmith.png';
import Elsa_ValayPaz from '../assets/images/team_socal_images/elsavalaypaz.png';
import Jeff_Walker from '../assets/images/team_socal_images/jeffwalker.png';
import Jennifer_Walker from '../assets/images/team_socal_images/jenniferwalker.png';
import Don_Widjaja from '../assets/images/team_socal_images/donwidjaja.png';
import Cedric_Williams from '../assets/images/team_socal_images/cedricwilliams.png';
import Gillian_Wright from '../assets/images/team_socal_images/gillianwright.png';
import Devin_Zornizer from '../assets/images/team_socal_images/devinzornizer.png';

import Hero_img from '../assets/images/LeaderShipTeam.jpeg'



const boardMembers = [
  { name: 'Scott Drury', title: 'Chief Executive Officer', location: 'Read more >', image: Scott_Drury },
  { name: 'Maryam S. Brown', title: 'President', location: 'Read more >', image: maryamsbrown },
  { name: 'Jimmie Cho', title: 'Chief Operating Officer', location: 'Read more >', image: Jimmie_Cho },
  { name: 'David Barrett', title: 'Senior Vice President and General Counsel', location: 'Read more >', image: David_Barrett },
];

const leadershipTeam = [  
  { name: 'David Buczkowski', title: 'Vice President, Gas Distribution', location: 'Read more >', image: David_Buczkowski },
  { name: 'Andy Carrasco', title: 'Vice President, Communications, Local Government and Community Affairs', location: 'Read more >', image: Andy_Carrasco },
  { name: 'Mia DeMontigny', title: 'Senior Vice President and Chief Financial Officer', location: 'Read more >', image: Mia_DeMontigny },
  { name: 'Paul Goldstein', title: 'Vice President, Gas Transmission and Storage', location: 'Read more >', image: Paul_Goldstein },
  { name: 'Ben Gordon', title: 'Senior Vice President, Chief Information Officer, and Chief Digital Officer', location: 'Read more >', image: Ben_Gordon },
  { name: 'Sandra Hrna', title: 'Vice President, Supply Chain and Operations Support', location: 'Read more >', image: Sandra_Hrna },
  { name: 'Kent Kauss', title: 'Regional Vice President, External Relations', location: 'Read more >', image: Kent_Kauss },
  { name: 'Jawwad Malik', title: 'Chief Strategy and Sustainability Officer', location: 'Read more >', image: Jawaad_Malik },
  { name: 'Sara Mijares', title: 'Vice President, Chief Accounting Officer, Assistant Treasurer and Controller', location: 'Read more >', image: Sara_Mijares },
  { name: 'Mitch Mitchell', title: 'Senior Vice President, Chief Legislative Officer', location: 'Read more >', image: Mitch_Mitchell },
  { name: 'Neil Navin', title: 'Senior Vice President, Engineering and Major Projects, and Chief Clean Fuels Officer', location: 'Read more >', image: Neil_Navin },
  { name: 'Deana Ng', title: 'Vice President, Chief Risk and Compliance Officer', location: 'Read more >', image: Deana_Ng },
  { name: 'Gina Orozco', title: 'Vice President, Gas Engineering and System Integrity', location: 'Read more >', image: Gina_Orozco },
  { name: 'Rodger R. Schwecke', title: 'Senior Vice President and Chief Infrastructure Officer', location: 'Read more >', image: Rodger_Schwecke },
  { name: 'Dan Skopec', title: 'Senior Vice President, State Government Affairs and Chief Regulatory Officer', location: 'Read more >', image: Dan_Skopec },
  { name: 'Erin M. Smith', title: 'Senior Vice President, Chief Talent, Culture, and Operations Support Officer', location: 'Read more >', image: Erin_Smith },
  { name: 'Elsa Valay-Paz', title: 'Vice President of Gas Acquisition', location: 'Read more >', image: Elsa_ValayPaz },
  { name: 'Jeff Walker', title: 'Senior Vice President, Systems & Technology', location: 'Read more >', image: Jeff_Walker },
  { name: 'Jennifer Walker', title: 'Senior Vice President, Customer Services and Digital Enablement', location: 'Read more >', image: Jennifer_Walker },
  { name: 'Don Widjaja', title: 'Vice President, Customer Services Field and Solutions', location: 'Read more >', image: Don_Widjaja },
  { name: 'Cedric Williams', title: 'Chief Safety Officer', location: 'Read more >', image: Cedric_Williams },
  { name: 'Gillian Wright', title: 'Senior Vice President and Chief Customer Officer', location: 'Read more >', image: Gillian_Wright },
  { name: 'Devin Zornizer', title: 'Vice President, Construction', location: 'Read more >', image: Devin_Zornizer },
];

const OurTeam = () => {
  return (
    <>
     <div className="waysToPay-first-container container-fluid mt-2 first-container">
  <div className="service_hero_img position-relative">
    <img
      src={Hero_img}
      alt="Service Hero"
      className="img-fluid w-100"
    />
    <h1 className="waysToPay-hero-heading text-start">
      Leadership Team
    </h1>
  </div>
</div>
      <div className="team-container">
        {/* Board of Directors Section */}
        <h2 className="team-heading">Board of Directors</h2>
        <hr className="team-heading-line" />
        <div className="team-grid-container">
          <div className="team-grid">
            {boardMembers.map((member, index) => (
              <div className="team-member" key={index}>
                <div className="team-member-image-wrapper">
                  <img src={member.image} alt={member.name} className="team-member-photo" />
                  <div className="team-member-info w-100">
                    <h3>{member.name}</h3>
                    <p className="team-member-title">{member.title}</p>
                  </div>
                </div>
                <p className="team-member-location"> <button className="learn-more-btn">Rread more</button> </p>
              </div>
            ))}
          </div>
        </div>

        {/* Leadership Team Section */}
        <h2 className="team-heading lead-team">Leadership Team</h2>
        <hr className="team-heading-line" />
        <div className="team-grid-container">
          <div className="team-grid">
            {leadershipTeam.map((member, index) => (
              <div className="team-member" key={index}>
                <div className="team-member-image-wrapper">
                  <img src={member.image} alt={member.name} className="team-member-photo" />
                  <div className="team-member-info w-100">
                    <h3>{member.name}</h3>
                    <p className="team-member-title">{member.title}</p>
                  </div>
                </div>
                <p className="team-member-location"><button className="learn-more-btn">Rread more</button></p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurTeam;
