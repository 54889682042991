import React from "react";
import ArticleHeroImg from "./assets/images/ArticleHeroImg2.jpg";
import "./pages/css/app.css";
import articleSectionImg1 from "./assets/images/articleSectionImgPg2.1.jpg";
import articleSectionImg2 from "./assets/images/articleSectionImgPg2.2.jpg";
const ArticlePg1 = () => {
  return (
    <>
      {/* article-first-container */}
      <div className="articlePg-first-container container-fluid mt-2">
        <div className="articlePg_hero_img position-relative">
          <img
            src={ArticleHeroImg}
            alt="Service Hero"
            className="img-fluid w-100"
            style={{ borderRadius: "5px" }}
          />
          <h1 className="articlePg-hero-heading text-start">
            SocalGas NewsRoom
          </h1>
        </div>
      </div>

      {/* article-second-container */}

      <div className="articlePg-second-container container mt-5">
        <div className="row">
          <div className="col-center">
            <h2>
              SoCalGas Launches New Fuel Card Program to Help Reduce Greenhouse
              Gas Emissions from Heavy-Duty Transportation Sector
            </h2>
            <hr className="mt-4"></hr>
            <div className="col d-flex flex-start">
              <h5 className="text-primary">Sep 9, 2024</h5>
            </div>
            <div className="col">
              <p className=" mt-3">
                LOS ANGELES – SoCalGas announced today it will make $1.5 million
                available to provide funds for 50 fuel cards to help accelerate
                the transition to low- and zero-emissions vehicles in the
                heavy-duty transportation sector. For 90-days, starting today
                through Dec. 8, 2024, companies that purchase a qualifying
                vehicle can apply for a fuel card through SoCalGas' Low Carbon
                Fuel Standard (LCFS) Fuel Card Incentive Program. The $30,000
                fuel card is designed to help support the transition to cleaner
                fuels in alignment with the California Air Resource Board (CARB)
                Scoping Plan for reaching carbon neutrality, by decreasing the
                demand for petroleum fuels to help reduce greenhouse gas
                emissions and improve air quality.
              </p>
            </div>
            <div className="col">
              {" "}
              <img
                src={articleSectionImg1}
                alt=""
                className="mt-3 img-fluid w-100 h-50"
              />
            </div>
            <hr className="mt-4" />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p className=" ">
              “As a company with approximately 5,000 light-, medium- and
              heavy-duty vehicles, as well as trailers and equipment, we
              understand the financial challenges that come with transitioning
              to a low- or zero-emissions fleet,” said Jawaad Malik, chief
              strategy and sustainability officer at SoCalGas. “By implementing
              innovative incentives like these fuel cards, we can help provide
              commercial fleet owners with significant cost savings to encourage
              their transition to a cleaner fleet, which ultimately contributes
              to a healthier environment and a more sustainable and resilient
              economy for California.”
            </p>

            <p className="">
              Under the CARB LCFS program, SoCalGas receives credits for
              procuring low emissions fuels. The credits lower fuel prices at
              SoCalGas’ 16 public access stations, which dispense 100% renewable
              natural gas (RNG). The creation of a fuel card incentive program
              is an additional way SoCalGas is giving credits back to customers
              in its service area to further support California’s climate and
              clean air goals.
            </p>

            <p className="">
              “We are excited that SoCalGas is offering this fuel card program
              that will provide significant savings for fleet operators,” said
              Hal Meriwether, regional general manager for Rush Truck Centers in
              California. “As the nation’s leading supplier of natural gas
              vehicles and consulting services, we are committed to helping
              customers make the best decisions for their business. This
              initiative makes the adoption of low- and zero-emission trucks
              more financially attractive for California fleets.”
            </p>

            <p className="">
              To participate in the SoCalGas LCFS Fuel Card Incentive Program
              applicants must purchase a Class 8 Heavy-Duty natural gas truck on
              or after the launch date of Sept. 9, 2024. Prioritization will be
              given to fleets with fewer than 10 vehicles. Selected applicants
              will receive a fuel card worth $30,000 that can be used at
              SoCalGas public access stations, while cards last.
            </p>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h2 className="mt-3">
              Cummins Praises SoCalGas for Advancing Renewable Natural Gas
              Market
            </h2>
          </div>

          <div className="col-12">
            <img
              src={articleSectionImg2}
              alt=""
              className="mt-3 img-fluid w-100"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p className="mt-5 ">
              SoCalGas is a leader among utilities in its sustainability goals
              and was among the first and largest natural gas distribution
              utilities in the United States to announce its aim to achieve
              net-zero GHG emissions by 2045. As part of its ASPIRE 2045
              sustainability strategy, SoCalGas has converted 38% of its
              over-the-road fleet1 to alternative fuel vehicles (AFV) with an
              aim to reach 50% by 2025 and operate a 100% zero-emissions fleet
              by 20352. SoCalGas was also recognized with the Leading Private
              Fleet Award at the Advanced Clean Transportation (ACT) Expo in
              2022 acknowledging the company’s efforts to go above and beyond
              what is required to achieve sustainability in fleet operations.
            </p>

            <p className="">
              The LCFS program was initially implemented in 2011 and is designed
              to encourage the use of cleaner low-carbon transportation fuels in
              California and the production of those fuels to reduce GHG
              emissions in the transportation sector. Learn more about SoCalGas’
              LCFS Fuel Card Incentive Program at socalgas.com/FuelCard.
            </p>

            <p className="">
              SoCalGas is the largest gas distribution utility in the United
              States serving approximately 21 million consumers across
              approximately 24,000 square miles of Central and Southern
              California. SoCalGas’ mission is to build the cleanest, safest,
              most innovative energy infrastructure company in America. SoCalGas
              aims to deliver affordable, reliable, and increasingly renewable
              gas service through its pipelines to help advance California's
              clean energy transition by supporting energy system reliability
              and resiliency and enabling the integration of renewable
              resources. SoCalGas is a recognized leader in its industry and
              community, as demonstrated by being named one of Reuters’ Top 100
              Innovators Leading the Global Energy Transition and Corporate
              Member of the Year by the Los Angeles Chamber of Commerce.
              SoCalGas is a subsidiary of Sempra (NYSE: SRE), a leading North
              American energy infrastructure company. For more information,
              visit SoCalGas.com/newsroom or connect with SoCalGas on social
              media @SoCalGas.
            </p>

            <hr className="mt-4" />
          </div>
        </div>

        <div className="row mt-4 article-sm-para">
          <div className="col">
            <p className="">
              This press release contains forward-looking statements within the
              meaning of the Private Securities Litigation Reform Act of 1995.
              Forward-looking statements are based on assumptions about the
              future, involve risks and uncertainties, and are not guarantees.
              Future results may differ materially from those expressed or
              implied in any forward-looking statement. These forward-looking
              statements represent our estimates and assumptions only as of the
              date of this press release. We assume no obligation to update or
              revise any forward-looking statement as a result of new
              information, future events or otherwise.
            </p>
            <p className="">
              n this press release, forward-looking statements can be identified
              by words such as “believe,” “expect,” “intend,” “anticipate,”
              “contemplate,” “plan,” “estimate,” “project,” “forecast,”
              “envision,” “should,” “could,” “would,” “will,” “confident,”
              “may,” “can,” “potential,” “possible,” “proposed,” “in process,”
              “construct,” “develop,” “opportunity,” “preliminary,”
              “initiative,” "target," "outlook," “optimistic,” “poised,”
              “positioned,” “maintain,” “continue,” “progress,” “advance,”
              “goal,” “aim,” “commit,” or similar expressions, or when we
              discuss our guidance, priorities, strategy, goals, vision,
              mission, opportunities, projections, intentions or expectations.
            </p>

            <p className="">
              Factors, among others, that could cause actual results and events
              to differ materially from those expressed or implied in any
              forward-looking statement include: decisions, investigations,
              inquiries, regulations, denials or revocations of permits,
              consents, approvals or other authorizations, renewals of
              franchises, and other actions, including the failure to honor
              contracts and commitments, by the (i) California Public Utilities
              Commission (CPUC), U.S. Department of Energy, U.S. Internal
              Revenue Service and other regulatory bodies and (ii) U.S. and
              states, counties, cities and other jurisdictions therein where we
              do business; the success of business development efforts and
              construction projects, including risks related to (i) completing
              construction projects or other transactions on schedule and
              budget, (ii) realizing anticipated benefits from any of these
              efforts if completed, (iii) obtaining third-party consents and
              approvals and (iv) third parties honoring their contracts and
              commitments; macroeconomic trends or other factors that could
              change our capital expenditure plans and their potential impact on
              rate base or other growth; litigation, arbitration and other
              proceedings, and changes (i) to laws and regulations, including
              those related to tax and trade policy and (ii) due to the results
              of elections; cybersecurity threats, including by state and
              state-sponsored actors, of ransomware or other attacks on our
              systems or the systems of third parties with which we conduct
              business, including the energy grid or other energy
              infrastructure; the availability, uses, sufficiency, and cost of
              capital resources and our ability to borrow money on favorable
              terms and meet our obligations, including due to (i) actions by
              credit rating agencies to downgrade our credit ratings or place
              those ratings on negative outlook, (ii) instability in the capital
              markets, or (iii) rising interest rates and inflation; the impact
              on affordability of our customer rates and our cost of capital and
              on our ability to pass through higher costs to customers due to
              (i) volatility in inflation, interest rates and commodity prices
              and (ii) the cost of meeting the demand for lower carbon and
              reliable energy in California; the impact of climate policies,
              laws, rules, regulations, trends and required disclosures,
              including actions to reduce or eliminate reliance on natural gas,
              increased uncertainty in the political or regulatory environment
              for California natural gas distribution companies, the risk of
              nonrecovery for stranded assets, and uncertainty related to
              emerging technologies; weather, natural disasters, pandemics,
              accidents, equipment failures, explosions, terrorism, information
              system outages or other events, such as work stoppages, that
              disrupt our operations, damage our facilities or systems, cause
              the release of harmful materials or fires or subject us to
              liability for damages, fines and penalties, some of which may not
              be recoverable through regulatory mechanisms or insurance or may
              impact our ability to obtain satisfactory levels of affordable
              insurance; the availability of natural gas and natural gas storage
              capacity, including disruptions caused by failures in the pipeline
              system or limitations on the withdrawal of natural gas from
              storage facilities; and other uncertainties, some of which are
              difficult to predict and beyond our control.
            </p>

            <p className="">
              Sempra Infrastructure, Sempra Infrastructure Partners, Sempra
              Texas, Sempra Texas Utilities, Oncor Electric Delivery Company LLC
              (Oncor) and Infraestructura Energética Nova, S.A.P.I. de C.V.
              (IEnova) are not the same companies as the California utilities,
              San Diego Gas & Electric Company or Southern California Gas
              Company, and Sempra Infrastructure, Sempra Infrastructure
              Partners, Sempra Texas, Sempra Texas Utilities, Oncor and IEnova
              are not regulated by the CPUC.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticlePg1;
