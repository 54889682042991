import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import React from "react";
import { useState } from "react";
import "./pages/css/app.css";
import CardImg1 from "./assets/images/aida-cardImg.svg";
import ProjectMonitring from "./assets/images/AIDA-project monitoring.svg";
import ResourcesAllocation from "./assets/images/AIDA-Resource Allocation.svg";
import PlanDesign from "./assets/images/AIDA-Urban-Planning-&-Design.svg";
import { Link } from "react-router-dom";
import AIDAAboutUsImg from "./assets/images/AIDAAboutusImg.svg";
import AIDALogo from "./assets/images/AIDA-Logo-1.png";
import SoCalGasLogo from "./assets/images/SocalGas-Logo.png";

const AIDAPg = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.message) newErrors.message = "Message is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Handle successful form submission (e.g., send data to the server)
      console.log("Form submitted:", formData);
      // Reset form
      setFormData({ name: "", email: "", message: "" });
    }
  };
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.state?.scrollToSection;
    if (sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  
  return (
    <>
      {/* AIDA-first-container */}
      <div className="AIDA-first-container container-fluid p-0 text-center">
        <div className="row justify-content-center">
          <h1 className="heading text-center py-md-2 mb-0 mt-sm-0 mt-md-4 mt-lg-5">
            American Industrial Development Association
          </h1>

          {/* Card container */}
          <div className="row-2 ">
            <div className="offset-md-3 col-md-6 offset-md-3">
              <div className="card aidaCollabCard d-flex flex-row align-items-center text-white">
                {/* <img
                  src={AIDALogo}
                  alt="Urban Development"
                  className="img-fluid border border-light rounded p-1"
                  style={{ width: "150px", height: "100%", objectFit: "cover" }}
                /> */}
                <div className="card-body aidaCollabCard-body border-0 p-4">
                  <h4 className="card-title">Collaborating with AIDA</h4>
                  <p className="card-text p-0 text-white">
                    Partnering with the nonprofit AIDA, we’re dedicated to
                    advancing urban infrastructure and sustainable technologies
                    to shape the future of city development.
                  </p>
                  {/* <div>
                    <button className="btn btn-primary">Learn More</button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  AIDI-second container */}
      <div className="container-fluid AIDA-second-container">
        <div className="row">
          <div className="col text-center text-white  fw-bold py-5">
            <h1>Transforming American Cities with Comprehensive Planning</h1>
          </div>
        </div>
      </div>

      {/* AIDA-third-container */}

      <div className="AIDA-third-container container assist-container mt-5">
        <h1 className="mb-5 billing-h1">AIDA's Role:</h1>
        <div className="row">
          <div className="col-md-7">
            <img
              src={CardImg1}
              alt="assistance progress"
              className="assist-prog-img img-fluid border border-md border-primary rounded"
            />
          </div>

          <div className="col-md-5 py-sm-0 px-sm-2 mt-sm-4 mt-md-0  mt-sm-5 mt-md-0 mt-4">
            <div className="card h-100 text-center">
              <div
                className="card-body px-2"
                style={{
                  backgroundColor: "rgb(216, 229, 240)",
                  borderRadius: "10px",
                }}
              >
                <div className="text-center">
                  <h3 className="text-primary mt-3">
                    Bridging Gaps to Revitalize Cities
                  </h3>
                </div>
                <p className=" mt-1">
                  AIDA works to fill gaps in urban planning, resources, and
                  funding to ensure successful project initiation and
                  completion, revitalizing American cities.
                </p>

                <p className=" mt-sm-1 mt-md-2 mt-lg-2">
                  AIDA bridges the gaps in urban planning, resources, and
                  funding to drive the successful launch and completion of
                  projects, playing a key role in the revitalization of American
                  cities.
                </p>
                <div className="d-flex justify-content-center mt-sm-2 mt-md-1 mt-lg-3 mt-xl-3 Billing-btn mb-4">
                  <div className="mt-auto">
                    <a href="#" className="">
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* AIDA-fourth-container */}
      <div className="container AIDA-fourth-container mt-3">
        <h1 className="AIDA-heading mt-5">Our Expertise in Modules</h1>
        <div className="row mt-5">
          {/* Card 1 */}
          <div className="col-sm-6 col-md-4 mt-4 mt-md-0">
            <div className="card expertise-card h-100 d-flex flex-column">
              <img
                src={ProjectMonitring}
                className="card-img-top img-fluid"
                style={{ borderRadius: "15px" }}
                alt="Project Monitoring"
              />
              <div className="card-body text-center flex-grow-1 p-4">
                <h4 className="card-title text-primary">Project Monitoring</h4>
                <p className="card-text ">
                  Developed with AIDA’s guidance, this module ensures efficient
                  project tracking to meet urban redevelopment goals, managing
                  timelines and resources effectively.
                </p>
                <div className="mt-auto AIDA-btn">
                  <a href="#" className="">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className="col-sm-6 col-md-4 mt-4 mt-md-0">
            <div className="card expertise-card h-100 d-flex flex-column">
              <img
                src={ResourcesAllocation}
                className="card-img-top img-fluid"
                style={{ borderRadius: "15px" }}
                alt="Resource Allocation"
              />
              <div className="card-body text-center flex-grow-1 p-4">
                <h4 className="card-title text-primary">Resource Allocation</h4>
                <p className="card-text ">
                  AIDA’s expertise informs this module, allowing for smart
                  resource distribution, ensuring projects receive the right
                  funding, materials, and workforce at the right time.
                </p>
                <div className="mt-auto  AIDA-btn">
                  <a href="#" className="">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className="col-sm-6 col-md-4 mt-4 mt-md-0">
            <div className="card expertise-card h-100 d-flex flex-column">
              <img
                src={PlanDesign}
                className="card-img-top img-fluid"
                style={{ borderRadius: "15px" }}
                alt="Urban Planning"
              />
              <div className="card-body text-center flex-grow-1 p-4">
                <h4 className="card-title text-primary">Urban Planning</h4>
                <p className="card-text ">
                  With AIDA’s insights, this module supports strategic urban
                  planning, fostering innovative designs and sustainable
                  solutions aligned with revitalization goals.
                </p>
                <div className="mt-auto AIDA-btn">
                  <a href="#" className="">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* AIDA-five-container  partnership*/}

      <div className="container AIDA-five-container mt-5">
        <h1 className="AIDA-heading mb-5">Our Partners</h1>
        <div className="row">
          {/* Partner Card I - SoCalGas */}
          <div className="col-md-6 mb-4">
            <div className="card partner-card h-100 text-center shadow-sm hover-shadow">
              <div
                className="img-container"
                style={{ height: "250px", overflow: "hidden" }}
              >
                <img
                  src={SoCalGasLogo}
                  className="card-img-top img-fluid partner-img"
                  alt="SoCalGas Logo"
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                />
              </div>
              <div className="card-body p-3">
                <h4 className="card-title text-primary">SoCalGas</h4>
                <p className="card-text ">
                  SoCalGas partners with AIDA to promote sustainable urban
                  development through innovative energy solutions and
                  community-focused projects.
                </p>
                <div className="mt-auto  AIDA-btn">
                  <a href="#" className="mt-3">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Partner Card II - AIDA */}
          <div className="col-md-6 mb-4">
            <div className="card partner-card h-100 text-center shadow-sm hover-shadow">
              <div
                className="img-container"
                style={{ height: "250px", overflow: "hidden" }}
              >
                <img
                  src={AIDALogo}
                  className="card-img-top img-fluid partner-img"
                  alt="AIDA Logo"
                  style={{ objectFit: "cover ", width: "100%", height: "100%" }}
                />
              </div>
              <div className="card-body p-3">
                <h4 className="card-title text-primary">AIDA</h4>
                <p className="card-text ">
                  AIDA leads urban revitalization efforts, bringing expertise in
                  project planning and sustainable development to American
                  cities.
                </p>
                <div className="mt-auto AIDA-btn">
                  <a href="#" className="mt-3">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* AIDA-six-container */}
      <div className="container AIDA-six-container AIDA-testimonials-container mt-5">
        <h1 className="AIDA-heading mb-5">Testimonials & Case Studies</h1>
        <div className="row">
          {/* Testimonial - Project Case Study 1 */}
          <div className="col-md-6 mb-4 text">
            <div className="card testimonial-card blue-hover-card h-100 shadow-sm">
              <div className="card-body p-3">
                <h4 className="card-title text-primary">
                  Revitalizing Downtown
                </h4>
                <p className="card-text ">
                  “Thanks to AIDA’s sustainable approach, our city’s downtown
                  area has transformed, bringing new opportunities and
                  eco-friendly designs to life. The project has positively
                  impacted both businesses and residents.”
                </p>
                <div className="d-flex justify-content-end">
                  <p className="">- City Official, City A</p>
                </div>
              </div>
            </div>
          </div>

          {/* Testimonial - Project Case Study 2 */}
          <div className="col-md-6 mb-4">
            <div className="card testimonial-card blue-hover-card h-100 shadow-sm">
              <div className="card-body p-3">
                <h4 className="card-title text-primary">
                  Affordable Housing Initiative
                </h4>
                <p className="card-text ">
                  “The Affordable Housing project led by AIDA has provided
                  quality, affordable housing solutions for thousands of
                  families, enhancing quality of life and urban stability.”
                </p>

                <div className="d-flex justify-content-end">
                  <p className="">
                    {" "}
                    – Housing Project Director, City B
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Additional Testimonial Example */}
          <div className="col-md-6 mb-4">
            <div className="card testimonial-card blue-hover-card h-100 shadow-sm">
              <div className="card-body p-3">
                <h4 className="card-title text-primary">
                  Eco-Infrastructure Project
                </h4>
                <p className="card-text ">
                  “AIDA’s expertise in sustainable development helped us
                  establish eco-friendly infrastructure. This initiative has
                  encouraged green practices across the city.”
                </p>

                <div className="d-flex justify-content-end">
                  <p className="">
                    {" "}
                    – Environmental Coordinator, City C
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* AIDA seven container */}

      <div className="AIDA-sevan-container container assist-container mt-5">
        <h1 className="mb-5 billing-h1">About Us</h1>
        <div className="row assistance-prog">
          <div className="col-md-7">
            <img
              src={AIDAAboutUsImg}
              alt="assistance progress"
              className="assist-prog-img img-fluid border border-md border-primary rounded"
            />
          </div>

          <div className="col-md-5 py-sm-0 px-sm-2 mt-sm-4 mt-md-0  mt-sm-5 mt-md-0 mt-4">
            <div className="card h-100 text-center">
              <div
                className="card-body px-3"
                style={{
                  backgroundColor: "rgb(216, 229, 240)",
                  borderRadius: "10px",
                }}
              >
                <h2 className="card-tex text-primary mt-sm-0 mt-md-2 mt-3">
                  Empowering Urban Transformation: The Story of AIDA
                </h2>
                <p className=" mt-sm-1 mt-md-2 mt-lg-2">
                  Discover our commitment to urban revitalization with
                  initiatives designed to promote sustainable development. We
                  offer financial support for qualifying projects, innovative
                  planning tools, and resources aimed at making cities healthier
                  and more resilient
                </p>
                <div className="d-flex justify-content-center mt-sm-2 mt-md-1 mt-lg-3 mt-xl-3 Billing-btn mb-4">
                  <Link to={"/aida-about-us"}>
                    <div className="mt-auto">
                      <a href="#" className="">
                        Learn More
                      </a>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* AIDA-eight-container */}
      <div className="container container" id="AIDAContactUs">
        <h1 className="AIDA-heading mt-5">Contact Us</h1>
      </div>
      <div className="AIDA-container AIDA-eight-container container mt-5">
        <div className="row">
          <div className="col">
            {" "}
            <div className="content">
              <div className="left-side">
                <div className="address details">
                  <i className="fas fa-map-marker-alt text-primary"></i>
                  <div className="topic">Address</div>
                  <div className="text-one">AIDA Head Office</div>
                  <div className="text-two">Airport Road,USA</div>
                </div>
                <div className="phone details">
                  <i className="fas fa-phone-alt text-primary"></i>
                  <div className="topic">Phone</div>
                  <div className="text-one">+0098 9893 5647</div>
                  <div className="text-two">+0096 3434 5678</div>
                </div>
                <div className="email details">
                  <i className="fas fa-envelope text-primary"></i>
                  <div className="topic">Email</div>
                  <div className="text-one">aida123@gmail.com</div>
                  <div className="text-two">info.aidalab@gmail.com</div>
                </div>
              </div>
              <div className="right-side">
                <div className="topic-text text-primary border border-primary rounded bg-light  text-center py-2">
                  <h5>
                    Contact Us for Information on AIDA’s Strategies necessary
                  </h5>
                  <div className=" mt-md-3 d-md-flex justify-content-around align-items-center">
                    <span className="fw-bold text-success">
                      Inquire About AIDA's Projects
                    </span>

                    <div className=" Billing-btn">
                 
                    <div className="mt-auto btn AIDA-btn">
                  <a href="#" className="mt-3">
                    Learn more
                  </a>
                </div>
              
                </div>
                  </div>
                </div>
                <form action="#">
                  <div className="input-box">
                    <input type="text" placeholder="Enter your name" required />
                  </div>
                  <div className="input-box">
                    <input
                      type="text"
                      placeholder="Enter your email"
                      required
                    />
                  </div>
                  <div className="input-box message-box">
                    <textarea placeholder="Enter your message"></textarea>
                  </div>
                  
                  
                  <div className="mt-auto  AIDA-btn d-flex justify-content-center mb-3">
                  <a href="#" className="mt-3">
                    SUBMIT
                  </a>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AIDAPg;
