import React, { useState } from "react";
import './css/app.css';
import fuel_img from '../assets/images/cleanfuel.png'
import hydrogen_img from '../assets/images/hydrogen.png'
import angeles_logo from '../assets/images/angeles-logo.png'
import gas_vehicle from '../assets/images/transport-img.png'

const TabbedSection = () => {
    // Manage active tab state
    const [activeTab, setActiveTab] = useState("Energy Solutions");

    const renderContent = () => {
        switch (activeTab) {
            case "Energy Solutions":
                return (
                    <div className="content-section">
                        <div className="content-block">
                            <img
                                src={fuel_img}
                                alt="Clean Fuels"
                                className="content-image"
                            />
                            <div className="content-text">
                                <h2>Clean Fuels</h2>
                                <p>
                                    Learn more about the essential role clean fuels like renewable
                                    natural gas and hydrogen can play in our energy future.
                                </p>
                                <button className="learn-more-btn">Learn More</button>
                            </div>
                        </div>
                        <div className="content-block">
                            <img
                                src={hydrogen_img}  
                                alt="Hydrogen"
                                className="content-image"
                            />
                            <div className="content-text">
                                <h2>Hydrogen</h2>
                                <p>
                                    We are demonstrating hydrogen development for California’s
                                    homes and businesses.
                                </p>
                                <button className="learn-more-btn">Learn More</button>
                            </div>
                        </div>
                    </div>
                );
            case "Clean Transportation":
                return (
                    <div className="content-section">

                        <div className="content-block">
                            <img
                                src={gas_vehicle}
                                alt="Clean Fuels"
                                className="content-image"
                            />
                            <div className="content-text">
                                <h2>Natural Gas Vehicles</h2>
                                <p>
                                    Find out how a natural gas vehicle may be able to help you save money and support the environment.
                                </p>
                                <button className="learn-more-btn">Learn More</button>
                            </div>
                        </div>

                    </div>
                );
            case "Distribution":
                return (
                    <div className="content-section">
                        <div className="content-block">
                            <img
                                src={angeles_logo}
                                alt="Clean Fuels"
                                className="content-image"
                            />
                            <div className="content-text">
                                <h2>Angeles Link</h2>
                                <p>
                                    Learn about our plan to develop the nation's largest clean, renewable hydrogen energy pipeline system.                                </p>
                                <button className="learn-more-btn">Learn More</button>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="tabs-container">
            <nav className="tab-header">
                <button
                    className={activeTab === "Energy Solutions" ? "active" : ""}
                    onClick={() => setActiveTab("Energy Solutions")}
                >
                    Energy Solutions
                </button>
                <button
                    className={activeTab === "Clean Transportation" ? "active" : ""}
                    onClick={() => setActiveTab("Clean Transportation")}
                >
                    Clean Transportation
                </button>
                <button
                    className={activeTab === "Distribution" ? "active" : ""}
                    onClick={() => setActiveTab("Distribution")}
                >
                    Distribution
                </button>
            </nav>
            <div className="tab-content-container">{renderContent()}</div>
        </div>
    );
};

export default TabbedSection;
