import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../pages/css/app.css";
import SoCalGasLogo from "../../assets/images/scg-logo-white.svg";
import SoCalGasLogoBlue from "../../assets/images/scg-logo-white.svg";
import { FaBars, FaTimes } from "react-icons/fa"; // Icons for menu toggle

const Header = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null); // To track which menu is open in mobile view

  const navbarStyle = {
    backgroundColor: isHomePage ? "transparent" : "#fff",
    color: isHomePage ? "#fff" : "#000",
  };

  const linkStyle = {
    color: isHomePage ? "#fff" : "#000",
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleSubMenu = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };

  return (
    <header>
      <nav className="navbar" style={navbarStyle}>
        <div className="navbar-logo">
          <Link to="/">
            {isHomePage ? (
              <img
                src={SoCalGasLogo}
                alt="SoCalGas Logo White"
                className="logo"
                height={35}
              />
            ) : (
              <img
                src={SoCalGasLogoBlue}
                alt="SoCalGas Logo Blue"
                className="logo"
                height={35}
              />
            )}
          </Link>
        </div>

        {/* Hamburger menu for mobile */}
        <button className="toggle-button" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? (
            <FaTimes size={28} color={"#fff"} />
          ) : (
            <FaBars size={28} color={"#fff"} />
          )}
        </button>

        {/* Desktop navbar menu */}
        <ul className={`navbar-menu ${isMobileMenuOpen ? "mobile-open" : ""}`}>
          <li className="navbar-item">
            <Link style={linkStyle} onClick={() => toggleSubMenu("myAccount")}>
              My Account
            </Link>
            <ul
              className={`dropdown ${
                activeMenu === "myAccount" && isMobileMenuOpen ? "open" : ""
              }`}
            >
              <li>
                <Link to="/commingsoon">Account Overview</Link>
              </li>
              <li>
                <Link to="/ways-to-pay">Billing and Payments</Link>
              </li>
              <li>
                <Link to="/service-management">Service Management</Link>
              </li>
              <li>
                <Link to="/commingsoon">Alerts and Notifications</Link>
              </li>
            </ul>
          </li>
          <li className="navbar-item">
            <Link
              to="#"
              style={linkStyle}
              onClick={() => toggleSubMenu("energyUsage")}
            >
              Energy Usage
            </Link>
            <ul
              className={`dropdown ${
                activeMenu === "energyUsage" && isMobileMenuOpen ? "open" : ""
              }`}
            >
              <li>
                <Link to="/commingSoon">Usage Overview</Link>
              </li>
              <li>
                <Link to="/commingSoon">Energy Insights</Link>
              </li>
              <li>
                <Link to="/commingSoon">Smart Devices</Link>
              </li>
              <li>
                <Link to="/commingSoon">Energy Reports</Link>
              </li>
            </ul>
          </li>

          <li className="navbar-item">
            <Link
              to="#"
              style={linkStyle}
              onClick={() => toggleSubMenu("aida")}
            >
              AIDA
            </Link>
            <ul
              className={`dropdown ${
                activeMenu === "aida" && isMobileMenuOpen ? "open" : ""
              }`}
            >
              <li>
                <Link to="/aida">Homepage</Link>
              </li>
              <li>
                <Link to="/aida-about-us">About Us</Link>
              </li>
              <li>
                <Link to="/aida" state={{ scrollToSection: "AIDAContactUs" }}>
                  Contact us
                </Link>
              </li>
            </ul>
          </li>

          <li className="navbar-item">
            <Link
              to="#"
              style={linkStyle}
              onClick={() => toggleSubMenu("marketPlace")}
            >
              Marketplace
            </Link>
            <ul
              className={`dropdown ${
                activeMenu === "marketPlace" && isMobileMenuOpen ? "open" : ""
              }`}
            >
              <li>
                <Link to="/marketPlace">Shop Energy Efficient Products</Link>
              </li>
              <li>
                <Link to="/commingSoon">Promotions and Offers</Link>
              </li>
              <li>
                <Link to="/commingSoon">Product Reviews and Ratings</Link>
              </li>
              <li>
                <Link to="/commingSoon">Financing Options</Link>
              </li>
            </ul>
          </li>
          <li className="navbar-item">
            <Link
              to="#"
              style={linkStyle}
              onClick={() => toggleSubMenu("support")}
            >
              Support & Contact
            </Link>
            <ul
              className={`dropdown ${
                activeMenu === "support" && isMobileMenuOpen ? "open" : ""
              }`}
            >
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              <li>
                <Link to="/commingSoon">Live Chat</Link>
              </li>
              <li>
                <Link to="/commingSoon">Schedule a Call</Link>
              </li>
              <li>
                <Link to="/commingSoon">FAQs</Link>
              </li>
              <li>
                <Link to="/commingSoon">Help Center</Link>
              </li>
              <li>
                <Link to="/commingSoon">Community Forum</Link>
              </li>
            </ul>
          </li>
          <li className="navbar-item">
            <Link
              to="#"
              style={linkStyle}
              onClick={() => toggleSubMenu("community")}
            >
              Community
            </Link>
            <ul
              className={`dropdown ${
                activeMenu === "community" && isMobileMenuOpen ? "open" : ""
              }`}
            >
              <li>
                <Link to="/commingSoon">Events and Webinars</Link>
              </li>
              <li>
                <Link to="/commingSoon">Community Initiatives</Link>
              </li>
              <li>
                <Link to="/commingSoon">Energy Savings Programs</Link>
              </li>
              <li>
                <Link to="/commingSoon">Feedback and Suggestions</Link>
              </li>
            </ul>
          </li>
          <li className="navbar-item">
            <Link
              to="#"
              style={linkStyle}
              onClick={() => toggleSubMenu("about")}
            >
              About Us
            </Link>
            <ul
              className={`dropdown ${
                activeMenu === "about" && isMobileMenuOpen ? "open" : ""
              }`}
            >
              <li>
                <Link to="/company-overview">Company Overview</Link>
              </li>
              <li>
                <Link to="/leadership-team">Leadership Team</Link>
              </li>
              <li>
                <Link to="/sustainability-initiatives">
                  Sustainability Initiatives
                </Link>
              </li>
              <li>
                <Link to="/careers">Careers</Link>
              </li>
            </ul>
          </li>
          <li className="navbar-item">
            <Link to="/commingSoon" className="navbar-login" style={linkStyle}>
              Login 
            </Link>
          </li>
        </ul>

        {/* Modal overlay */}
        {isMobileMenuOpen && (
          <div className="modal-overlay" onClick={toggleMobileMenu}></div>
        )}
      </nav>
    </header>
  );
};

export default Header;
