import { MapContainer, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';
import React from 'react';
import 'leaflet/dist/leaflet.css'; // Import the CSS for proper map styling
import L from 'leaflet';
import Gasicon from '../assets/images/gaslocationtank.svg'
// Custom icon definition
const customIcon = new L.Icon({
  iconUrl:Gasicon , // Replace with your icon URL or local image path
  iconSize: [20, 40], // Size of the icon
  iconAnchor: [20, 40], // Point of the icon which will correspond to the marker's location
  popupAnchor: [0, -40], // Point from which the popup should open relative to the iconAnchor
});
const ContactMap = () => {
  // Coordinates for 555 West 5th Street, Los Angeles, California
  const location = [34.050536, -118.255104];

  return (
    <div className='container mt-3'>
      <MapContainer 
        center={location} 
        zoom={20} 
        scrollWheelZoom={false} 
        style={{ height: '600px', width: '100%' }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={location} icon={customIcon}> {/* Using custom icon */}
          <Popup>
            555 West 5th Street, Los Angeles, California, 90013-1011
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default ContactMap;
