import React from "react";
import ArticleHeroImg from "./assets/images/ArticleHeroImg3.jpg";
import "./pages/css/app.css";
import articleSectionImg1 from "./assets/images/articleSectionImgPg3.1.jpg";
import articleSectionImg2 from "./assets/images/articleSectionImgPg3.2.jpg";
const ArticlePg1 = () => {
  return (
    <>
      {/* article-first-container */}
      <div className="articlePg-first-container container-fluid mt-2">
        <div className="articlePg_hero_img position-relative">
          <img
            src={ArticleHeroImg}
            alt="Service Hero"
            className="img-fluid w-100"
            style={{ borderRadius: "5px" }}
          />
          <h1 className="articlePg-hero-heading text-start">
            SocalGas NewsRoom
          </h1>
        </div>
      </div>

      {/* article-second-container */}

      <div className="articlePg-second-container container mt-5">
        <div className="row">
          <div className="col text-center">
            <h2>
            Transforming Communities by Providing Fresh, Locally Grown Produce to Families in Los Angeles, Promoting Health and Sustainability for a Better Future
            </h2>
            <hr className="mt-4"></hr>
            <div className="col d-flex flex-start">
              <h5 className="text-primary">Jul 25, 2024</h5>
            </div>
            <div className="col">
              <p className=" mt-3">
              On a hot Saturday morning in June, nearly 60 SoCalGas volunteers gathered to support the nonprofit organization CultivaLA to harvest zucchini, apples, peppers, tomatoes, rosemary, lavender, and lemon verbena on their five-acre community farm in South El Monte. SoCalGas also presented CultivaLA with a donation of $20,000 that will support the nonprofit in distributing over 22,500 pounds of food.
              </p>
            </div>
            <div className="col">
              {" "}
              <img
                src={articleSectionImg1}
                alt=""
                className="mt-3 img-fluid w-100"              
              />
            </div>
            <hr className="mt-4" />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p className=" ">
            CultivaLA’s community garden in South El Monte is a beacon of hope for San Gabriel Valley families,” said Los Angeles County Supervisor Hilda L. Solis, who represents the First District. “The organization has proven to be a reliable community partner with impactful results – empowering community members through nutrition education, teaching them to grow their produce, inspiring entrepreneurship, and connecting them to social services. I am proud to have designated this space for our community and appreciate the support of SoCalGas to help provide more access to local fresh sustenance for our most vulnerable.
            </p>

            <p className="">
            CultivaLA provides healthy food access to underserved communities, and as many children are home for summer vacation, families may need access to healthy foods. The harvested items will be available to individuals and families for free at the nonprofit’s Mercado Al Aire Libre (Free Farmer’s Market), which travels to locations in South El Monte, Westlake district, and MacArthur Park in Greater Los Angeles.
            </p>

            <p className="">
            SoCalGas is committed to making a positive difference in the communities it serves, and the support to CultivaLA is a testament to this ongoing dedication. By addressing food insecurity in Los Angeles County, SoCalGas and its allies aim to create a healthier and more sustainable future for all.
            </p>

            <p className="">
            SoCalGas’ collaboration with CultivaLA is part of the company’s ASPIRE 2045 sustainability goals, which includes a plan to invest $50 million to drive positive change in diverse and underserved communities across five years.

For more information on CultivaLA, or to volunteer, visit <span><a href="#">https://www.cultivala.org/</a></span>
            </p>
            <hr />
          </div>
        </div>

        <div className="row">

          <div className="col">
            <h2 className="mt-3">
            SoCalGas Supports CultivaLA to Combat Food Insecurity in Los Angeles
            </h2>
          </div>

          <div className="col-12">
            <img src={articleSectionImg2} alt="" className="mt-3 img-fluid w-100" />
          </div>
        </div>

       <hr className="mt-4"/>

        <div className="row mt-4 article-sm-para">
          <div className="col">
            <p className="">
              This press release contains forward-looking statements within the
              meaning of the Private Securities Litigation Reform Act of 1995.
              Forward-looking statements are based on assumptions about the
              future, involve risks and uncertainties, and are not guarantees.
              Future results may differ materially from those expressed or
              implied in any forward-looking statement. These forward-looking
              statements represent our estimates and assumptions only as of the
              date of this press release. We assume no obligation to update or
              revise any forward-looking statement as a result of new
              information, future events or otherwise.
            </p>
            <p className="">
              In this press release, forward-looking statements can be
              identified by words such as “believe,” “expect,” “intend,”
              “anticipate,” “contemplate,” “plan,” “estimate,” “project,”
              “forecast,” “envision,” “should,” “could,” “would,” “will,”
              “confident,” “may,” “can,” “potential,” “possible,” “proposed,”
              “in process,” “construct,” “develop,” “opportunity,”
              “preliminary,” “initiative,” "target," "outlook," “optimistic,”
              “poised,” “positioned,” “maintain,” “continue,” “progress,”
              “advance,” “goal,” “aim,” “commit,” or similar expressions, or
              when we discuss our guidance, priorities, strategy, goals, vision,
              mission, opportunities, projections, intentions or expectations.
            </p>

            <p className="">
              Factors, among others, that could cause actual results and events
              to differ materially from those expressed or implied in any
              forward-looking statement include: decisions, investigations,
              inquiries, regulations, denials or revocations of permits,
              consents, approvals or other authorizations, renewals of
              franchises, and other actions, including the failure to honor
              contracts and commitments, by the (i) California Public Utilities
              Commission (CPUC), U.S. Department of Energy, U.S. Internal
              Revenue Service and other regulatory bodies and (ii) U.S. and
              states, counties, cities and other jurisdictions therein where we
              do business; the success of business development efforts and
              construction projects, including risks related to (i) completing
              construction projects or other transactions on schedule and
              budget, (ii) realizing anticipated benefits from any of these
              efforts if completed, (iii) obtaining third-party consents and
              approvals and (iv) third parties honoring their contracts and
              commitments; macroeconomic trends or other factors that could
              change our capital expenditure plans and their potential impact on
              rate base or other growth; litigation, arbitration and other
              proceedings, and changes (i) to laws and regulations, including
              those related to tax and trade policy and (ii) due to the results
              of elections; cybersecurity threats, including by state and
              state-sponsored actors, of ransomware or other attacks on our
              systems or the systems of third parties with which we conduct
              business, including the energy grid or other energy
              infrastructure; the availability, uses, sufficiency, and cost of
              capital resources and our ability to borrow money on favorable
              terms and meet our obligations, including due to (i) actions by
              credit rating agencies to downgrade our credit ratings or place
              those ratings on negative outlook, (ii) instability in the capital
              markets, or (iii) rising interest rates and inflation; the impact
              on affordability of our customer rates and our cost of capital and
              on our ability to pass through higher costs to customers due to
              (i) volatility in inflation, interest rates and commodity prices
              and (ii) the cost of meeting the demand for lower carbon and
              reliable energy in California; the impact of climate policies,
              laws, rules, regulations, trends and required disclosures,
              including actions to reduce or eliminate reliance on natural gas,
              increased uncertainty in the political or regulatory environment
              for California natural gas distribution companies, the risk of
              nonrecovery for stranded assets, and uncertainty related to
              emerging technologies; weather, natural disasters, pandemics,
              accidents, equipment failures, explosions, terrorism, information
              system outages or other events, such as work stoppages, that
              disrupt our operations, damage our facilities or systems, cause
              the release of harmful materials or fires or subject us to
              liability for damages, fines and penalties, some of which may not
              be recoverable through regulatory mechanisms or insurance or may
              impact our ability to obtain satisfactory levels of affordable
              insurance; the availability of natural gas and natural gas storage
              capacity, including disruptions caused by failures in the pipeline
              system or limitations on the withdrawal of natural gas from
              storage facilities; and other uncertainties, some of which are
              difficult to predict and beyond our control.
            </p>

            <p className="">
              Sempra Infrastructure, Sempra Infrastructure Partners, Sempra
              Texas, Sempra Texas Utilities, Oncor Electric Delivery Company LLC
              (Oncor) and Infraestructura Energética Nova, S.A.P.I. de C.V.
              (IEnova) are not the same companies as the California utilities,
              San Diego Gas & Electric Company or Southern California Gas
              Company, and Sempra Infrastructure, Sempra Infrastructure
              Partners, Sempra Texas, Sempra Texas Utilities, Oncor and IEnova
              are not regulated by the CPUC.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticlePg1;
