import React from "react";
import "./pages/css/app.css";
import Hero_img from "./assets/images/AIDAAboutUsHeroImg.jpg";
import BusiObjImg1 from './assets/images/AIDIAboutUscrdImg1.svg'
import BusiObjImg2 from './assets/images/AIDIAboutUscrdImg2.svg'
import BusiObjImg3 from './assets/images/AIDIAboutUscrdImg3.svg'
import BusiObjImg4 from './assets/images/AIDIAboutUscrdImg4.svg'

import { Link } from "react-router-dom";

const AIDAAboutUs = () => {
  return (
    <>
      {/* about us first container */}
      <div className="AIDAAboutUs-first-container container-fluid mt-2 first-container">
        <div className="row">
          <div className="col">
            <h1 className="text-white">About Us AIDA</h1>
          </div>
        </div>
      </div>

      {/* about us second container */}

      <div className="AIDAAboutUs-second-container container">
        <h1 className="AIDA-heading mt-5">Our Values</h1>
        <div className="row mt-5">
          <b>
            At AIDA we live by three values: Do the Right Thing, Champion People
            and Shape the Future.
          </b>
          <div className="col">
            <p className="p mt-3">
              These principles shape AIDA’s core identity. They influence our
              vision and guide our initiatives. As a key advocate for urban
              redevelopment in America, these values shape how we approach every
              opportunity and tackle each challenge.
            </p>
            <p className="p">
              With these principles as our foundation, our mission statement
              provides a clear, actionable path forward. At AIDA, we’re
              committed to transforming urban landscapes through sustainable,
              innovative, and inclusive development.
            </p>
            <p className="p">
              With these principles as our foundation, our mission statement
              provides a clear, actionable path forward. At AIDA, we’re
              committed to transforming urban landscapes through sustainable,
              innovative, and inclusive development.
            </p>
          </div>
        </div>
      </div>

      {/* AIDA-third-container  */}

      <div className="AIDAAboutUs-third-container container">
        <h1 className="AIDA-heading mt-5">AIDA's Content</h1>
        <div className="row mt-5">
            <div className="col bg-primary border border-light rounded">
                <h3 className="lh-sm text-white py-4 text-center">The American Industrial Development Association (AIDA),
founded by Dr. Alexander, spearheads the redevelopment of
American cities, focusing on sustainable, modern technologies and
smart city development.</h3>

            </div>
        </div>
      </div>

        {/* AIDA four container */}

        <div className="container mt-5 AIDAAboutUs-four-container">
  <h1 className="AIDA-heading mb-4">Our Business Objectives</h1>
  <div className="row mt-5">
    {/* Card 1 - Urban Infrastructure */}
    <div className="col-sm-6 col-md-4 mb-4">
      <div className="card h-100 text-center custom-card">
        <img
          src={BusiObjImg1} 
          className="card-img-top img-fluid"
          style={{ borderRadius: "10px 10px 0 0", height: "200px", width: "100" }} 
          alt="Urban Infrastructure"
        />
        <div className="card-body p-3">
          <h5 className="card-title text-primary">Urban Infrastructure</h5>
          <p className="card-text ">
            Upgrade city infrastructure with sustainable materials and smart technologies to create efficient urban spaces.
          </p>
        </div>
      </div>
    </div>

    {/* Card 2 - Affordable Housing */}
    <div className="col-sm-6 col-md-4 mb-4">
      <div className="card h-100 text-center custom-card">
        <img
          src={BusiObjImg2} 
          className="card-img-top img-fluid"
          style={{ borderRadius: "10px 10px 0 0", height: "200px", width: "100%" }} 
          alt="Affordable Housing"
        />
        <div className="card-body p-3">
          <h5 className="card-title text-primary">Affordable Housing</h5>
          <p className="card-text ">
            Develop modular, low-cost housing solutions to address urban housing challenges and promote accessible living.
          </p>
        </div>
      </div>
    </div>

    {/* Card 3 - Sustainability */}
    <div className="col-sm-6 col-md-4 mb-4">
      <div className="card h-100 text-center custom-card">
        <img
          src={BusiObjImg3} 
          className="card-img-top img-fluid"
          style={{ borderRadius: "10px 10px 0 0", height: "200px", width: "100%" }}
          alt="Sustainability"
        />
        <div className="card-body p-3">
          <h5 className="card-title text-primary">Sustainability</h5>
          <p className="card-text ">
            Implement carbon capture and eco-friendly materials in construction to minimize environmental impact.
          </p>
        </div>
      </div>
    </div>

    {/* Card 4 - Community Engagement */}
    <div className="col-sm-6 col-md-4 mb-4">
      <div className="card h-100 text-center custom-card">
        <img
          src={BusiObjImg4} 
          className="card-img-top img-fluid"
          style={{ borderRadius: "10px 10px 0 0", height: "200px", width: "100%" }} 
          alt="Community Engagement"
        />
        <div className="card-body p-3">
          <h5 className="card-title text-primary">Community Engagement</h5>
          <p className="card-text ">
            Leverage SoCalGas’s community outreach to foster local involvement and ensure inclusive urban development.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>



    </>
  );
};

export default AIDAAboutUs;
