import "./Billing.css";
import energySavingTipsTool from "../../assets/images/manageHiBill-energy-saving-tipTool.svg";
import assistanceProgram from "../../assets/images/manageHiBill-assist-programs.svg";
import billTrackerAlert from "../../assets/images/manageHiBill-bill-tracker-Alerts.svg";
import levelPayPlan from "../../assets/images/manageHiBill-level-pay-plan.svg";
import testImg from "../../assets/images/bill_women.jpg";
import { Bar, Doughnut } from "react-chartjs-2";
import ManageHiBillHeroImg from '../../assets/images/manageBill-hero-img.jpg'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"; // Import everything here, no need to declare twice

// Register the required components for Bar and Doughnut charts
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement, // Include ArcElement for Doughnut chart
  Title,
  Tooltip,
  Legend
);
  // variables for vertical graph
  const data1 = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Gas Charges ($)",
        data: [75, 85, 65, 90, 110, 130], // Replace with actual dollar amounts for each month
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Gas Charges Over the Past Months",
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: "Charges in Dollars ($)",
        },
        beginAtZero: true, // Start the y-axis at zero
      },
    },
  };

  // variables for donut pie graph
  const data2 = {
    labels: ['January', 'February', 'March'],
    datasets: [
      {
        label: 'Gas Usage (in Therms)',
        data: [30, 30, 30], // Replace with actual data for 2024
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(255, 99, 132, 0.6)',
          'rgba(255, 206, 86, 0.6)',
         
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)'          
        ],
        borderWidth: 1,
      },
    ],
  };

  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Gas Usage in 2024',
      },
    },
  };



const Manage_higherBill = () => {
  // variables for vertical graph
  const data1 = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Gas Charges ($)",
        data: [75, 85, 65, 90, 110, 130], // Replace with actual dollar amounts for each month
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Gas Charges Over the Past Months",
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: "Charges in Dollars ($)",
        },
        beginAtZero: true, // Start the y-axis at zero
      },
    },
  };


  // variables for donut pie graph
  const data2 = {
    labels: ['Natural Gas Commodity charge', 'natural Gas Transportation Rates', 'Public Purpose Program'],
    datasets: [
      {
        label: 'Gas Usage (in Therms)',
        data: [30, 30, 30], // Replace with actual data for 2024
        backgroundColor: [
          'rgba(75, 192, 192)',
          'pink',
          'rgb(88, 240, 88)'
          
        ],
        borderColor: [
          'rgba(75, 192, 192)',
          'pink',
          'rgb(88, 240, 88)',
          
        ],
        borderWidth: 1,
      },
    ],
  };

  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Gas Usage in 2024',
      },
    },
  };
  return (
    <>
      {/* manageBill first container & first container */}

      {/* <div className="manageHiBill-hero-container container-fluid">
        <div className="row">
          <div className="col d-flex align-items-center">
            <h1 className="manageHiBill-heading manageHiBill-hero-heading">
              Manage Higher Bill
            </h1>
          </div>
        </div>
      </div> */}

<div className="manageHiBill-first-container container-fluid mt-2 first-container">
  <div className="service_hero_img position-relative">
    <img
      src={ManageHiBillHeroImg}
      alt="Service Hero"
      className="img-fluid w-100"
      style={{ borderRadius: "5px" }}
    />
    <h1 className="manageHiBill-hero-heading text-start">
    Manage Higher Bills
    </h1>
  </div>
</div>


      {/* manageHiBill second container */}
      
            <div className="manageHiBill-second-container container">
              <div className="row">
                <div className="col">
                  <p className="mt-5">
                    <h4><b>
                      We are committed to delivering affordable natural gas and
                      work to secure the best prices for the natural gas we
                      purchase on behalf of our residential and small business
                      customers.
                    </b></h4>
                  </p>
                  <p className="mt-3">
                    Natural gas prices fluctuate based on regional and national
                    markets, and we work diligently to secure cost-effective
                    natural gas supplies. The cost of buying natural gas is
                    billed to customers with no markup, meaning we do not earn
                    additional profits from the sale of natural gas or higher
                    supply prices.{" "}
                  </p>
                  <p className="">
                    We are here year-round to help our customers understand
                    their bills, while also helping them prepare for colder
                    weather and the rising costs of energy.
                  </p>
                </div>
              </div>
            </div>
        

      {/* manageHiBill third Container */}

      <div className="manageHiBill-third-container container mt-5">
        <h1 className="manageHiBill-heading">How We Can Help You</h1>
        <div className="row row-cols-1 row-cols-md-3 g-4 mt-5">
          <div className="col-sm-6 col-md-4 col-lg-3 d-flex align-items-stretch text-center">
            <div className="card h-100">
              <div className="div-img">
                <img
                  src={energySavingTipsTool}
                  alt="Energy Savings Tips and Tools"
                  className="img-fluid"
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">Energy Savings Tips and Tools</h5>
                <p className="card-text">
                  Discover ways to reduce your energy consumption and lower your
                  bills. Access tools that help track your energy usage and find
                  savings opportunities.
                </p>
                <button className="mt-auto btn Billing-btn">
                  <a href="#" className="mt-3">
                    Learn more
                  </a>
                </button>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3 d-flex align-items-stretch text-center">
            <div className="card h-100">
              <div className="div-img">
                <img
                  src={assistanceProgram}
                  alt="Assistance Programs"
                  className="img-fluid"
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">Assistance Programs</h5>
                <p className="card-text">
                  We offer financial assistance programs to help you manage your
                  energy bills during tough times. Learn about payment plans and
                  other resources.
                </p>
                <button className="mt-auto btn Billing-btn">
                  <a href="#" className="mt-3">
                    Learn more
                  </a>
                </button>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3 d-flex align-items-stretch text-center">
            <div className="card h-100">
              <div className="div-img">
                <img
                  src={billTrackerAlert}
                  alt="Bill Tracker Alerts"
                  className="img-fluid"
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">Bill Tracker Alerts</h5>
                <p className="card-text">
                  Stay informed with real-time bill tracker alerts. Get
                  notifications when you're nearing your budget or when your
                  energy usage spikes.
                </p>
                <button className="mt-auto btn Billing-btn">
                  <a href="#" className="mt-3">
                    Learn More
                  </a>
                </button>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-3 d-flex align-items-stretch text-center">
            <div className="card h-100">
              <div className="div-img">
                <img
                  src={levelPayPlan}
                  alt="Budget Plans"
                  className="img-fluid"
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">Budget Plans</h5>
                <p className="card-text">
                  Enroll in budget plans to help you manage your energy costs
                  throughout the year. Predictable monthly bills will make
                  budgeting easier.
                </p>
                <button className="mt-auto btn Billing-btn">
                  <a href="#" className="mt-3">
                    {" "}
                    Enroll{" "}
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* manageHiBill four container */}

      <div className="manageHiBil-four-container container-fluid">
        <div className="row">
          <div className="col">
            <div className="manageHiBill-four-container container mt-5">
              <h1 className="manageHiBill-heading">More Information</h1>
              <div className="row row-cols-1 row-cols-md-3 g-4 mt-4">
                <div className="col text-center">
                  <div className="card h-100">
                    <div className="card-body">
                      <h5 className="card-title">
                        Energy Savings Tips and Tools
                      </h5>
                      <p className="card-text">
                        Discover ways to reduce your energy consumption and
                        lower your bills. Access tools that help track your
                        energy usage and find savings opportunities.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col text-center">
                  <div className="card  h-100">
                    <div className="card-body">
                      <h5 className="card-title">Assistance Programs</h5>
                      <p className="card-text">
                        We offer financial assistance programs to help you
                        manage your energy bills during tough times. Learn about
                        payment plans and other resources.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col text-center">
                  <div className="card  h-100">
                    <div className="card-body">
                      <h5 className="card-title">Bill Tracker Alerts</h5>
                      <p className="card-text">
                        Stay informed with real-time bill tracker alerts. Get
                        notifications when you're nearing your budget or when
                        your energy usage spikes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* manageHiBill Five Container */}

      <div className="manageHiBill-five-container container mt-5">
        <h1 className="manageHiBill-heading">More Resources</h1>
        <div className="row mt-5">
          <div className="col">
            <div className="card border-0">
              <img src={testImg} alt="Card Image" className="card-img-top" />
              <div className="card-body p-4">
                <h5 className="card-title">Understanding My Bill</h5>
                <p className="card-text">
                  Learn about everything that goes into calculating your monthly
                  natural gas bill.
                </p>
                <button className="btn Billing-btn">
                  <a href="#" className="">
                    Learn More
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

     
      {/* ManageHiBill Six Container Graph */}
      <div className="manageHiBill-six-container container mt-5">
        <h1 className="manageHiBill-heading">
          What Contributes to a Higher SoCalGas Bill?
        </h1>
        <div className="row mt-5">
          <div className="col-md-12 p-4">
            <Bar data={data1} options={options1} />
          </div>
        </div>
      </div>


      {/* manageHiBill-Seven-Container */}

      <div className="manageHiBill-Seven-Container container mt-5">
        <div className="row mt-5">
          <div className="col">
            <h1 className="manageHiBill-heading">
              What Are the Charges Included in My SoCalGas Bill?
            </h1>
            <p className="mt-3">
              Our rates are regulated and approved by the California Public
              Utilities Commission (CPUC). Natural gas bills consist of three
              cost components:
            </p>{" "}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
          <h2 className="manageHiBill-heading">Natural Gas Commodity Charge</h2>
          <p className="mt-3">
            The cost of natural gas purchased by SoCalGas on behalf of its
            customers.
          </p>
          <h2 className="manageHiBill-heading">
            Natural Gas Transportation Rates
          </h2>
          <p className="mt-3">
            The cost to deliver natural gas to a customer's home or business.
          </p>
          <h2 className="manageHiBill-heading">Public Purpose Surcharge</h2>
          <p className="mt-3">
            A CPUC mandated charge to fund public purpose programs such as
            California Alternate Rates for Energy (CARE), the Energy Savings
            Assistance Program, energy efficiency, and research.
          </p>
          </div>
        </div>
      </div>

    {/* ManageHiBill Eight Container Donut Chart */}
<div className="ManageHiBill-eight-container container mt-5">
  <h1 className="manageHiBill-heading">Understanding Location-Based Taxes and Fees</h1>
  <div className="row mt-5">
    <div className="col-12 border pt-4 pb-4">
      <h2 className="manageHiBill-heading text-center mb-2">September 2024 Rates</h2>
      <div className="donut-chart-wrapper">
        <Doughnut data={data2} options={options2} />
      </div>
    </div>
  </div>
</div>


 {/* ManageHiBill nine Container Donut Chart */}

 <div className="ManageHiBill-nine-Container container mt-5">
  <h1 className="manageHiBill-heading">Learn the Factors Behind Your Gas Bill</h1>
  <div className="row mt-2 mb-4">
    <div className="col-12">
      <p>The costs of purchasing and transporting natural gas can vary from year to year and within the same year. Our transportation rates, regulated by the CPUC, are typically adjusted annually at the start of each year. In some cases, these rates may change during the year as authorized by the CPUC. </p>
  <h3 className="manageHiBill-heading mt-3">Increase in Appliance Usage </h3>
  <p>Increased use of appliances can impact your bill. For example, water heaters work longer and harder during colder weather and could increase your natural gas usage. Having guests at home, cooking more, and leaving appliances on could also impact your bill.  </p>
  <h3 className="manageHiBill-heading mt-3">Days Within Your Billing Cycle </h3>
  <p>There are typically 29-32 days in a billing cycle, depending on when meters are read for a particular area. However, some situations may change the number of billing days. For example, holidays can add up to four days to your billing cycle.  </p>
  <h3 className="manageHiBill-heading mt-3">Past Due Amount </h3>
  <p>Any remaining past due amount included in your current bill will cause it to be higher.</p>
    </div>
  </div>
</div>

      
    </>
  );
};

export default Manage_higherBill;
