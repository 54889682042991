import React from "react";
import ArticleHeroImg from "./assets/images/ArticleHeroImg1.jpg";
import "./pages/css/app.css";
import articleSectionImg1 from "./assets/images/articleSectionImgPg1.1.jpg";
import articleSectionImg2 from "./assets/images/articleSectionImgPg1.2.jpg";
const ArticlePg1 = () => {
  return (
    <>
      {/* article-first-container */}
      <div className="articlePg-first-container container-fluid mt-2">
        <div className="articlePg_hero_img position-relative">
          <img
            src={ArticleHeroImg}
            alt="Service Hero"
            className="img-fluid w-100"
            style={{ borderRadius: "5px" }}
          />
          <h1 className="articlePg-hero-heading text-start">
            SocalGas NewsRoom
          </h1>
        </div>
      </div>

      {/* article-second-container */}

      <div className="articlePg-second-container container mt-5">
        <div className="row">
          <div className="col text-center">
            <h2>
              Boyle Heights Solis S.T.E.M. Magnet High School’s First All-Girls
              Team Competes on International Stage at the Horizon Hydrogen Grand
              Prix World Finals
            </h2>
            <hr className="mt-4"></hr>
            <div className="col d-flex flex-start">
              <h5 className="text-primary">Sep 10, 2024</h5>
            </div>
            <div className="col">
              <p className=" mt-3">
                This is the Boyle Heights Solis S.T.E.M. Magnet High School
                second trip to the world finals. In 2023, the Los STEMateros
                team participated in the Hydrogen Grand Prix World Finals in Las
                Vegas. The Hydrogen Grand Prix (H2GP) challenges tomorrow's
                innovators to design, engineer, build and race their own
                hydrogen-powered cars.
              </p>
            </div>
            <div className="col">
              {" "}
              <img
                src={articleSectionImg1}
                alt=""
                className="mt-3 img-fluid w-100 h-50"
              />
            </div>
            <hr className="mt-4" />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p className=" ">
              LOS ANGELES – SoCalGas announced that the Boyle Heights Solis
              S.T.E.M. Magnet High School will compete tomorrow against teams
              representing 14 countries at the Hydrogen Grand Prix World Finals
              in Anaheim, California, taking place September 9 to 12. Sponsored
              by SoCalGas, the all-girls team, Las STEMateras, built a hydrogen
              fuel cell, remote-control race car that will compete in a six-hour
              race. This competition gives students the opportunity to use their
              knowledge of clean energy and apply their skills to hands-on
              projects.
            </p>

            <p className="">
              This is the Boyle Heights Solis S.T.E.M. Magnet High School second
              trip to the world finals. In 2023, the Los STEMateros team
              participated in the Hydrogen Grand Prix World Finals in Las Vegas.
              The Hydrogen Grand Prix (H2GP) challenges tomorrow's innovators to
              design, engineer, build and race their own hydrogen-powered cars.
            </p>

            <p className="">
              “We are so proud of the Boyle Heights Hilda Solis HS team for
              earning a spot to compete on an international stage,” LAUSD Board
              Member Dr. Rocío Rivas said. “Their families and school
              communities will be rooting for them at every turn because of the
              teamwork, grit, and innovation. Let’s go!!!”
            </p>

            <p className="">
              Despite making up 47% of the national workforce, women only make
              up about 32% of the renewable energy workforce, according to Save
              on Energy. Opportunities like H2GP are important for providing
              accessible opportunities to all students pursuing careers in clean
              energy.
            </p>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h2 className="mt-3">
              H2GP’s S.T.E.M Curriculum Prepares Students for California’s
              Energy Transition
            </h2>
          </div>

          <div className="col-12">
            <img
              src={articleSectionImg2}
              alt=""
              className="mt-3 img-fluid w-100"
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p className="mt-5 ">
              H2GP’s S.T.E.M curriculum exposes students to skills that are
              essential for California’s energy transition. With SoCalGas’ H2
              Innovation Experience in Downey, and the recently revamped
              SoCalGas storefront at Junior Achievement of Southern California
              (JASoCal) JA Finance Park, SoCalGas provides practical, hands-on
              experience for students to learn meaningful lessons in
              sustainability and career exploration in S.T.E.M. fields.
            </p>

            <p className="">
              SoCalGas is committed to supporting the communities it serves,
              working to provide equitable opportunities while taking tangible
              steps towards a carbon neutral future. By providing support and
              resources for students towards higher education and career
              development, SoCalGas aspires to empower communities and help
              young leaders for success.
            </p>

            <p className="">
              Under the ASPIRE 2045 Sustainability Strategy, SoCalGas plans to
              invest $50 million over five years into communities the company
              serves, working to advance racial and gender diversity in the
              workplace and taking tangible steps towards a carbon neutral
              future. By providing resources for higher education and career
              development, SoCalGas aspires to empower communities and help
              prepare young leaders for success.
            </p>

            <p className="">
              SoCalGas is the largest gas distribution utility in the United
              States serving approximately 21 million consumers across
              approximately 24,000 square miles of Central and Southern
              California. SoCalGas’ mission is to build the cleanest, safest,
              most innovative energy infrastructure company in America. SoCalGas
              aims to deliver affordable, reliable, and increasingly renewable
              gas service through its pipelines to help advance California's
              clean energy transition by supporting energy system reliability
              and resiliency and enabling the integration of renewable
              resources. SoCalGas is a recognized leader in its industry and
              community
            </p>
            <hr className="mt-4" />
          </div>
        </div>

        <div className="row mt-4 article-sm-para">
          <div className="col">
            <p className="">
              This press release contains forward-looking statements within the
              meaning of the Private Securities Litigation Reform Act of 1995.
              Forward-looking statements are based on assumptions about the
              future, involve risks and uncertainties, and are not guarantees.
              Future results may differ materially from those expressed or
              implied in any forward-looking statement. These forward-looking
              statements represent our estimates and assumptions only as of the
              date of this press release. We assume no obligation to update or
              revise any forward-looking statement as a result of new
              information, future events or otherwise.
            </p>
            <p className="">
              In this press release, forward-looking statements can be
              identified by words such as “believe,” “expect,” “intend,”
              “anticipate,” “contemplate,” “plan,” “estimate,” “project,”
              “forecast,” “envision,” “should,” “could,” “would,” “will,”
              “confident,” “may,” “can,” “potential,” “possible,” “proposed,”
              “in process,” “construct,” “develop,” “opportunity,”
              “preliminary,” “initiative,” "target," "outlook," “optimistic,”
              “poised,” “positioned,” “maintain,” “continue,” “progress,”
              “advance,” “goal,” “aim,” “commit,” or similar expressions, or
              when we discuss our guidance, priorities, strategy, goals, vision,
              mission, opportunities, projections, intentions or expectations.
            </p>

            <p className="">
              Factors, among others, that could cause actual results and events
              to differ materially from those expressed or implied in any
              forward-looking statement include: decisions, investigations,
              inquiries, regulations, denials or revocations of permits,
              consents, approvals or other authorizations, renewals of
              franchises, and other actions, including the failure to honor
              contracts and commitments, by the (i) California Public Utilities
              Commission (CPUC), U.S. Department of Energy, U.S. Internal
              Revenue Service and other regulatory bodies and (ii) U.S. and
              states, counties, cities and other jurisdictions therein where we
              do business; the success of business development efforts and
              construction projects, including risks related to (i) completing
              construction projects or other transactions on schedule and
              budget, (ii) realizing anticipated benefits from any of these
              efforts if completed, (iii) obtaining third-party consents and
              approvals and (iv) third parties honoring their contracts and
              commitments; macroeconomic trends or other factors that could
              change our capital expenditure plans and their potential impact on
              rate base or other growth; litigation, arbitration and other
              proceedings, and changes (i) to laws and regulations, including
              those related to tax and trade policy and (ii) due to the results
              of elections; cybersecurity threats, including by state and
              state-sponsored actors, of ransomware or other attacks on our
              systems or the systems of third parties with which we conduct
              business, including the energy grid or other energy
              infrastructure; the availability, uses, sufficiency, and cost of
              capital resources and our ability to borrow money on favorable
              terms and meet our obligations, including due to (i) actions by
              credit rating agencies to downgrade our credit ratings or place
              those ratings on negative outlook, (ii) instability in the capital
              markets, or (iii) rising interest rates and inflation; the impact
              on affordability of our customer rates and our cost of capital and
              on our ability to pass through higher costs to customers due to
              (i) volatility in inflation, interest rates and commodity prices
              and (ii) the cost of meeting the demand for lower carbon and
              reliable energy in California; the impact of climate policies,
              laws, rules, regulations, trends and required disclosures,
              including actions to reduce or eliminate reliance on natural gas,
              increased uncertainty in the political or regulatory environment
              for California natural gas distribution companies, the risk of
              nonrecovery for stranded assets, and uncertainty related to
              emerging technologies; weather, natural disasters, pandemics,
              accidents, equipment failures, explosions, terrorism, information
              system outages or other events, such as work stoppages, that
              disrupt our operations, damage our facilities or systems, cause
              the release of harmful materials or fires or subject us to
              liability for damages, fines and penalties, some of which may not
              be recoverable through regulatory mechanisms or insurance or may
              impact our ability to obtain satisfactory levels of affordable
              insurance; the availability of natural gas and natural gas storage
              capacity, including disruptions caused by failures in the pipeline
              system or limitations on the withdrawal of natural gas from
              storage facilities; and other uncertainties, some of which are
              difficult to predict and beyond our control.
            </p>

            <p className="">
              Sempra Infrastructure, Sempra Infrastructure Partners, Sempra
              Texas, Sempra Texas Utilities, Oncor Electric Delivery Company LLC
              (Oncor) and Infraestructura Energética Nova, S.A.P.I. de C.V.
              (IEnova) are not the same companies as the California utilities,
              San Diego Gas & Electric Company or Southern California Gas
              Company, and Sempra Infrastructure, Sempra Infrastructure
              Partners, Sempra Texas, Sempra Texas Utilities, Oncor and IEnova
              are not regulated by the CPUC.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticlePg1;
