/* eslint-disable react/jsx-no-undef */
import "./Billing.css";
import UnderstandBill_HeroImg from "../../assets/images/understand_my_Bill_Hero_Img.jpg";
import UnderstandBill_CardImg from "../../assets/images/understand-myBill-cardImg.jpg";
import UnderstandHiBill from "../../assets/images/Understand_HiBill.svg";
import PaperLessBill from "../../assets/images/understandMyBill-PaperlessBills.svg";
import CommidityCoast from "../../assets/images/understandMyBill-Commidity-coast.svg";
import PublicPUrchaseSurgery from "../../assets/images/understandMyBill-publicPurchase.svg";
import Pay_in_person from "../../assets/images/Pay_to_person.svg";
import PaperLess_CardImg from "../../assets/images/PapaerLessCardImg.jpg";
import NaturalGasMeter from "../../assets/images/UnderstandMyBill-NaturalGasMeter.jpg";
import HomeServicesCharges from "../../assets/images/UnderstandMyBill-HomeService-charges.jpg";
import GasMeterIcon from "../../assets/images/NaturalGasMeterIcon.svg";
import HomeServiceIcon from "../../assets/images/HomeServiceChargesIcon.svg";

import AccountSummaryicon from "../../assets/images/Account-Summary.svg";
import UsageHistoryicon from "../../assets/images/Usage-History.svg";
import BaselineAllowanceicon from "../../assets/images/BaselineAllowance.svg";
import OverBaselineChargesicon from "../../assets/images/OverBaselineCharges.svg";
import GasTransportationicon from "../../assets/images/GasTransportation.svg";
import TaxesAndFees from "../../assets/images/TaxesAndFees.svg";
const Understand_myBill = () => {
  return (
    <>
      {/* understand my Bill first-container */}
      <div className="understandMyBill-first-container container-fluid mt-2">
        <div className="understandMyBill_hero_img position-relative">
          <img
            src={UnderstandBill_HeroImg}
            alt="Service Hero"
            className="img-fluid w-100"
            style={{ borderRadius: "5px" }}
          />
          <h1 className="understandMyBill-hero-heading text-start">
            Understand My Bill
          </h1>
        </div>
      </div>

      {/* understandMyBill-eight-container */}

      <div className="understandMyBill-eight-container container mt-5">
        <h1 className="manageHiBill-heading">
          A Step-by-Step Approach to Your SoCalGas Bill Calculation
        </h1>
        <div className="row">
          <div className="col step1 border border-primary rounded mt-5">
            <ol className="ol-cards">
              <li style={{ "--accent-color": "#00206D" }}>
                <div className="icon">
                  <img
                    src={AccountSummaryicon}
                    alt="Energy Savings Tips and Tools"
                    className="img-fluid"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div className="title">Account Summary</div>
                <div className="descr mt-4">
                  The "Account Summary" section highlights payments received,
                  current charges, due date, and the amount due.
                </div>
              </li>
              <li style={{ "--accent-color": "#00206D" }}>
                <div className="icon">
                  <img
                    src={UsageHistoryicon}
                    alt="Energy Savings Tips and Tools"
                    className="img-fluid"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div className="title">Usage History</div>
                <div className="descr mt-4">
                  The "Usage History" bar graph shows your natural gas usage
                  over the past 13 months.
                </div>
              </li>
              <li style={{ "--accent-color": "#00206D" }}>
                <div className="icon">
                  <img
                    src={BaselineAllowanceicon}
                    alt="Energy Savings Tips and Tools"
                    className="img-fluid"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div className="title">Baseline Allowance</div>
                <div className="descr mt-4">
                  Baseline Therms represent the natural gas allowance for basic
                  needs, billed at the lowest rate.
                </div>
              </li>
              <li style={{ "--accent-color": "#00206D" }}>
                <div className="icon">
                  <img
                    src={OverBaselineChargesicon}
                    alt="Energy Savings Tips and Tools"
                    className="img-fluid"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div className="title">Over-Baseline Charges</div>
                <div className="descr mt-4">
                  If you exceed the baseline, you will be charged at a higher
                  rate per therm for the extra usage.
                </div>
              </li>

              <li style={{ "--accent-color": "#00206D" }}>
                <div className="icon">
                  <img
                    src={GasTransportationicon}
                    alt="Energy Savings Tips and Tools"
                    className="img-fluid"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div className="title">Gas Transportation</div>
                <div className="descr mt-4">
                  The "Gas Transportation" table details the cost to deliver
                  natural gas to your home, charged at baseline and
                  over-baseline rates.
                </div>
              </li>

              <li style={{ "--accent-color": "#00206D" }}>
                <div className="icon">
                  <img
                    src={TaxesAndFees}
                    alt="Energy Savings Tips and Tools"
                    className="img-fluid"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div className="title">Taxes and Fees</div>
                <div className="descr mt-4">
                  The "Taxes & Fees on Gas Charges" section includes state
                  regulatory fees, the public purpose surcharge, and utility
                  users tax.
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>

      {/* UnderstandMyBill second container*/}

      <div className="understandMyBill-second-container container understandMyBill-container mt-5">
        <h1 className="mb-5 billing-h1">How To Read My Bill</h1>
        <div className="row understandMyBill-section">
          <div className="col-md-7">
            <img
              src={UnderstandBill_CardImg}
              alt="assistance progress"
              className="understandMyBill-img img-fluid border border-sm border-primary rounded"
            />
          </div>
          <div className="col-md-5 mt-sm-5 mt-md-0 mt-4">
            <div className="card h-100 text-center">
              <div
                className="card-body px-3"
                style={{
                  backgroundColor: "rgb(216, 229, 240)",
                  borderRadius: "10px",
                }}
              >
                <h1 className="mt-sm-1 mt-lg-4 text-center">
                  <img
                    src={UnderstandHiBill}
                    alt="Energy Savings Tips and Tools"
                    className="img-fluid mt-3"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                    }}
                  />
                </h1>
                <h3 className="card-text  mt-4 text-primary" style={{fontSize:"20px"}}>
                  Reading Your Bill in Detail
                </h3>
                <p className=" mt-4">
                  ExGet to know the specific sections of your bill. We'll
                  explain each one to ensure you have a full understanding of
                  your energy usage and the associated costs.
                </p>
                <div className="d-flex justify-content-center mt-5 Billing-btn mb-4">
                  <div className="mt-auto">
                    <a href="#" className="mt-3">
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*understandMyBill third container */}

      <div className="understandMyBill-third-container container mt-5">
        <div className="row row-cols-1 row-cols-md-3 g-4 mt-5">
          <div className="col-sm-6 col-md-6 col-lg-4 d-flex align-items-stretch text-center">
            <div className="card h-100">
              <div className="div-img">
                <img
                  src={CommidityCoast}
                  alt="Energy Savings Tips and Tools"
                  className="img-fluid"
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">Commodity Costs</h5>
                <p className="card-text">
                  Factors that could impact your bill include the commodity cost
                  of natural gas (also referred to as the “core procurement gas
                  price”), seasonal variations, an increase in your natural gas
                  appliance usage, weather conditions, and any remaining unpaid
                  balance from prior bills.
                </p>
                <button className="mt-auto btn Billing-btn">
                  <a href="#" className="mt-3">
                    Learn More
                  </a>
                </button>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-6 col-lg-4 d-flex align-items-stretch text-center">
            <div className="card h-100">
              <div className="div-img">
                <img
                  src={PublicPUrchaseSurgery}
                  alt="Assistance Programs"
                  className="img-fluid"
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">Public Purchase Surcharge</h5>
                <p className="card-text">
                  The commodity cost of natural gas is just one factor that
                  makes up the total charges on a customer’s bill. Natural Gas
                  Transportation Rates and Public Purpose Surcharges also make
                  up charges that customers can expect to see on their bills.
                </p>
                <button className="mt-auto btn Billing-btn">
                  <a href="#" className="mt-3">
                    Learn More
                  </a>
                </button>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-6 col-lg-4 d-flex align-items-stretch text-center">
            <div className="card h-100">
              <div className="div-img">
                <img
                  src={Pay_in_person}
                  alt="Bill Tracker Alerts"
                  className="img-fluid"
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">Transportation Rates</h5>
                <p className="card-text">
                  New gas transportation rates, approved by the CPUC, took
                  effect on January 1, 2023. Residential rates increased by
                  about 8%, and small business rates by 5%. These adjustments
                  help us upgrade infrastructure, retain skilled employees, and
                  support diverse energy solutions.
                </p>
                <button className="mt-auto btn Billing-btn">
                  <a href="#" className="mt-3">
                    Learn More
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row mt-5"
          style={{
            backgroundColor: "rgb(216, 229, 240)",
            borderRadius: "10px",
          }}
        >
          <div className="col">
            <p className="mt-4 ">
              <b className="lh-lg">
                Overall, about 92% of your bill goes toward products and
                services provided to you essentially at cost, with the remaining
                eight percent going toward a return on SoCalGas’ investment in
                the gas system. More information about how these charges are
                reflected on your bill can be found here
              </b>
            </p>
          </div>
        </div>
      </div>

      {/* understandMyBill four container */}

      <div className="understandMyBill-four-container container mt-5">
        <h1 className="manageHiBill-heading">
          The Process Behind Rate Calculations
        </h1>
        <div className="row row-cols-1 row-cols-md-3 g-4 mt-4">
          <div className="col text-center">
            <div className="card h-100">
              <div className="card-body">
                <h1 className="card-title">Propose</h1>
                <p className="card-text mt-3 ">
                  SoCalGas evaluates the future needs of the natural gas system
                  and estimates the funding required for the next three years.
                  This includes upgrading infrastructure, ensuring safe
                  operations, investing in new technology, and improving
                  customer service. After careful analysis, the necessary budget
                  is determined.
                </p>
              </div>
            </div>
          </div>

          <div className="col text-center">
            <div className="card  h-100">
              <div className="card-body">
                <h5 className="card-title">Review</h5>
                <p className="card-text mt-3 ">
                  The CPUC spends two to three years holding public hearings,
                  reviewing input, and analyzing feedback before setting
                  SoCalGas' budget for the next three years. Public advocates
                  contribute by commenting on system plans and costs, raising
                  public interest concerns. The CPUC then finalizes the budget
                  after reviewing all input
                </p>
              </div>
            </div>
          </div>

          <div className="col text-center">
            <div className="card  h-100">
              <div className="card-body">
                <h5 className="card-title">Implement</h5>
                <p className="card-text mt-3 ">
                  The CPUC allocates funding increases through rate hikes for
                  different natural gas customer categories. Commercial and
                  industrial users typically bear a larger share due to their
                  higher usage. Residential rates are tiered, with higher gas
                  users paying a larger portion of the system's operational and
                  maintenance costs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* understanMyBill five container */}

      <div className="understandMyBill-five-container container understandMyBill-container mt-5">
        <h1 className="mb-5 billing-h1">Go Green with Paperless Billing</h1>
        <div className="row understandMyBill-section">
          <div className="col-md-7">
            <img
              src={PaperLess_CardImg}
              alt="assistance progress"
              className="understandMyBill-img img-fluid border border-primary rounded"
            />
          </div>
          <div className="col-md-5 mt-sm-5 mt-md-0 mt-4">
            <div className="card h-100 text-center">
              <div
                className="card-body px-3"
                style={{
                  backgroundColor: "rgb(216, 229, 240)",
                  borderRadius: "10px",
                }}
              >
                <h1 className="mt-2 text-center">
                  <img
                    src={PaperLessBill}
                    alt="Energy Savings Tips and Tools"
                    className="img-fluid"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                    }}
                  />
                </h1>
                <h3 className="card-text  mt-4 text-primary" style={{fontSize:"20px"}}>
                  Paperless Billing
                </h3>
                <p className=" mt-4">
                  Make the switch today to simplify your life and contribute to
                  a greener planet. Every small change counts, and together, we
                  can create a more sustainable future
                </p>
                <div className="d-flex justify-content-center mt-3 Billing-btn mb-4">
                  <div className="mt-auto ">
                    <a href="#" className="mt-3">
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* understanMyBill six container */}

      <div className="manageHiBill-six-container container mt-5">
  <div className="row manageHiBill-courses_box">
    <div className="col-sm-12 col-md-6">
      <div className="card manageHiBill-courses_item h-100 w-100">
        <div
          className="card-body manageHiBill-courses-item_link manageHiBill-courses-item_1"
        >
          <div className="manageHiBill-courses-item_bg"></div>
          <h5 className="manageHiBill-courses-item_title">
            Save time and money
          </h5>
          <p className="manageHiBill-courses-item_date-box mt-4 ">
            Receive your bill via email and conveniently make payments online
            through My Account.
          </p>
        </div>
      </div>
    </div>

    <div className="col-sm-12 col-md-6">
      <div className="card manageHiBill-courses_item h-100 w-100">
        <div
          className="card-body manageHiBill-courses-item_link manageHiBill-courses-item_2"
        >
          <div className="manageHiBill-courses-item_bg"></div>
          <h5 className="manageHiBill-courses-item_title">
            Help the environment
          </h5>
          <p className="manageHiBill-courses-item_date-box mt-4 ">
            You will not only eliminate paper from the bill, but also
            envelopes, bill inserts, checks, and stamps.
          </p>
        </div>
      </div>
    </div>

    <div className="col-sm-12 col-md-6">
      <div className="card manageHiBill-courses_item h-100 w-100">
        <div
          className="card-body manageHiBill-courses-item_link manageHiBill-courses-item_3"
        >
          <div className="manageHiBill-courses-item_bg"></div>
          <h5 className="manageHiBill-courses-item_title">
            Embrace digital wave
          </h5>
          <p className="manageHiBill-courses-item_date-box mt-4 ">
            Join over 3.7 million of your neighbors who are paperless. We
            can create a more sustainable future.
          </p>
        </div>
      </div>
    </div>

    <div className="col-sm-12 col-md-6">
      <div className="card manageHiBill-courses_item h-100 w-100">
        <div
          className="card-body manageHiBill-courses-item_link manageHiBill-courses-item_4"
        >
          <div className="manageHiBill-courses-item_bg"></div>
          <h5 className="manageHiBill-courses-item_title">
            Go green today!
          </h5>
          <p className="manageHiBill-courses-item_date-box mt-4 ">
            Embracing a paperless lifestyle not only simplifies your daily
            tasks but also contributes.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

      {/* understanMyBill seven container */}

      <div className="understandMyBill-five-container container understandMyBill-container">
        <h1 className="mb-5 billing-h1">
          Demystifying Your Natural Gas Meter Reading Process
        </h1>
        <div className="row understandMyBill-section">
          <div className="col-md-7">
            <img
              src={NaturalGasMeter}
              alt="assistance progress"
              className="understandMyBill-img img-fluid border border-primary rounded"
            />
          </div>
          <div className="col-md-5 mt-sm-5 mt-md-0 mt-4">
            <div className="card h-100 text-center">
              <div
                className="card-body px-3"
                style={{
                  backgroundColor: "rgb(216, 229, 240)",
                  borderRadius: "10px",
                }}
              >
                <div className="mt-sm-1 mt-lg-2 text-center">
                  <img
                    src={GasMeterIcon}
                    alt="Energy Savings Tips and Tools"
                    className="img-fluid mt-3"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <h3 className="card-text  mt-sm-1 mt-lg-3 text-primary" style={{fontSize:"20px"}}>
                  How to Read a Natural Gas Meter
                </h3>
                <p className=" mt-sm-1 mt-lg-4">
                  Reading your natural gas meter is simple once you understand
                  the basics. Each meter typically has a series of dials or a
                  digital display that shows your gas usage. Start by reading
                  the dials from left to right, noting the numbers each pointer
                  is on or just passed.
                </p>
                <div className="d-flex justify-content-center mt-sm-1 mt-lg-3 mt-xl-4 Billing-btn mb-4">
                  <div className="">
                    <a href="#" className="">
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* understanMyBill eight container */}

      <div className="understandMyBill-five-container container understandMyBill-container mt-5">
        <h1 className="mb-5 billing-h1">HomeServe Charges on My Bill</h1>
        <div className="row understandMyBill-section">
          <div className="col-md-7">
            <img
              src={HomeServicesCharges}
              alt="assistance progress"
              className="understandMyBill-img img-fluid border border-primary rounded"
            />
          </div>
          <div className="col-md-5 mt-sm-5 mt-md-0 mt-4">
            <div className="card h-100 text-center">
              <div
                className="card-body px-3"
                style={{
                  backgroundColor: "rgb(216, 229, 240)",
                  borderRadius: "10px",
                }}
              >
                <h1 className="mt-sm-1 mt-lg-4 text-center">
                  <img
                    src={HomeServiceIcon}
                    alt="Energy Savings Tips and Tools"
                    className="img-fluid mt-3"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "contain",
                    }}
                  />
                </h1>
                <h3 className="card-text  mt-sm-1 mt-lg-3 text-primary" style={{fontSize:"20px"}}>
                  HomeServe Charges on My Bill
                </h3>
                <p className=" mt-sm-1 mt-lg-4">
                  Questions about third-party charges on your natural gas bill?
                  As a convenience to customers, we've been providing billing
                  services to third parties selling energy-related or home
                  safety-related products or services since the late 1990s
                </p>
                <div className="d-flex justify-content-center mt-sm-1 mt-lg-3 Billing-btn mb-4">
                  <div className="mt-auto">
                    <a href="#" className="">
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* the understndMyBil-eight-contianer place to first position */}




      {/* nine container */}

      <div className="container understandMyBill-nine-container">
        <div className="row">
          <div className="col">      
       


          </div>
        </div>
      </div>
    </>
  );
};

export default Understand_myBill;
