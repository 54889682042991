import React from 'react';
import WorkInProgress from './assets/images/work-in-progress.svg';
import './pages/billingpage/Billing.css';

const CommingSoon = () => {
  return (
    <>
      <div className="container-fluid mt-5 comming-soon text-center d-flex align-items-center justify-content-center">
        <div className="row">
          <div className="col">
            <div className="keyboard">
              <span className="key">C</span>
              <span className="key">O</span>
              <span className="key">M</span>
              <span className="key">I</span>
              <span className="key">N</span>
              <span className="key">G</span>
              <span className="key">&nbsp;&nbsp;</span>
            <span className="soon">
            <span className="key">S</span> {/* Added class "soon" */}
              <span className="key">O</span>
              <span className="key">O</span>
              <span className="key">N</span>
            </span>
            </div>
            <img src={WorkInProgress} alt="" className='img-flid comingSoonImg mb-5' />
          </div>
        </div>
      </div>
    </>
  );
}

export default CommingSoon;
