import React from "react";
import service_heroimg from "../../assets/images/service_heroimg.jpg";
import Start_serviceimg from "../../assets/images/start_serviceimg.svg";
import Transport_service from "../../assets/images/Transport_ServiceIcon.svg";
import Stop_serviceimg from "../../assets/images/Stop_serviceimg.svg";
import Restore_serviceimg from "../../assets/images/service_restore_img.jpg";
import Servie_builderimg from "../../assets/images/service-builderimg.jpg";
import service_appointment from '../../assets/images/Schedule Service Appointmenticon.svg'
import request from '../../assets/images/request icon.svg'
import Relight from '../../assets/images/pilot relight.svg'
import { useState } from "react";
import './services.css'


const Service = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index); // Toggle visibility
  };
  const faqData = [
    {
      question: "How does starting a service differ from transferring it?",
      answer:
        "Transferring service is intended for customers who currently have gas service and want to move it to a different address within our service area. Starting service is for both new and existing customers who need to initiate gas service at a new or existing address. If you wish to change or update the name on an existing account, you can do so by accessing the Manage Account Information section.",
    },
    {
      question: "What are the requirements to start a service?",
      answer: "Your new address must fall within our service area. A gas meter needs to be installed at the new location. If there isn't a meter, please submit a Request for Residential Gas Service to arrange for installation. Have your social security number ready, as it will be used to validate your identity through Experian, a national credit reporting agency. SoCalGas® employs secure technology to protect your personal information. If you cannot provide your social security number, please call 1-877-238-0092 for assistance. A one-time service fee of $25 will be included in your first bill.",
    },
    {
      question: "What information is required to transfer a service?",
      answer: "Your new address must be in our service area. A meter must be installed. If there is no meter, please submit a Request for Residential Gas Service to have a meter installed. SoCalGas® uses proven, trusted technology to protect your personal information. A one-time service fee of $25 will be added to your first bill. A technician may need to enter your residence to turn on gas at the new address.",
    },
    {
      question: "How can I restore service if it has been disconnected?",
      answer: "To restore service call us at 1-877-238-0092.",
    },
  ];
  return (
    <>
      {/* Hero Image Section */}
       <div className="waysToPay-first-container container-fluid mt-2 first-container">
  <div className="service_hero_img position-relative">
    <img
      src={service_heroimg}
      alt="Service Hero"
      className="img-fluid w-100"
    />
    <h1 className="waysToPay-hero-heading text-start">
     Service Management
    </h1>
  </div>
</div>

      {/* Service Cards */}
      <div className="container mt-5">
        <h1 className="service_heading_STS mt-2 mb-5 ">
          Need to start, transfer, or stop service?
        </h1>
        <div className="row g-4 mt-5 justify-content-center mb-5">
          {/* Start Service Card */}
          <div className="col-sm-12 col-md-6 col-lg-4 mt-5 one">
            <div className="service-card p-4 text-center border rounded">
              <img
                src={Start_serviceimg}
                alt="Start Service"
                className="img-fluid service_icons two"
              />
              <h4 className="mt-3 service_subheading text-primary two">Start Service</h4>
              <p className="service-subheading-text  two">
                You're a new customer and want to start service right away.
              </p>
             <button className="learn-more-btn two">Start Service</button>
            </div>
          </div>

          {/* Transfer Service Card */}
          <div className="col-sm-12 col-md-6 col-lg-4 mt-5 one">
            <div className="service-card p-4 text-center border rounded">
              <img
                src={Transport_service}
                alt="Transfer Service"
                className="img-fluid service_icons two"
              />
              <h4 className="mt-3 service_subheading text-primary two">Moving</h4>
              <p className="service-subheading-text two ">
                You're an existing customer and want to transfer service to a
                new address.
              </p>
              <button className="learn-more-btn two ">Transfer Service</button>
            </div>
          </div>

          {/* Stop Service Card */}
          <div className="col-sm-12 col-md-6 col-lg-4 mt-5 one">
            <div className="service-card p-4 text-center border rounded">
              <img
                src={Stop_serviceimg}
                alt="Stop Service"
                className="img-fluid service_icons two"
              />
              <h4 className="mt-3 service_subheading text-primary two">Stop Service</h4>
              <p className="service-subheading-text  two">
                You're an existing customer and want to stop service at your
                current address.
              </p>
             <button className="learn-more-btn two">Stop Service</button>
                       </div>
          </div>
        </div>

        {/* Service Appointment Section */}
        <div className="row g-3 mt-5 justify-content-center">
  <h1 className="service_appoinment-heading ">
    Service Appointments
  </h1>

  {/* Schedule Service Appointment */}
  <div className="col-sm-12 col-md-6 col-lg-4">
    <div className="service-card-appoinment border p-4 text-center">
    <img src={service_appointment} alt="" style={{height:"70px"}} className="" />
      <h4 className="service-title text-primary mt-2">Schedule a service appointment</h4>
      <p className="service-appoinment-text">
        Get a SoCalGas technician to service an appliance, tune-up equipment
      </p>
      <a href="#" className="service-appoinemt-link">
        <button className="learn-more-btn">Schedule <span>&#8250;</span></button>
      </a>
    </div>
  </div>

  {/* Look Up Service Request */}
  <div className="col-sm-12 col-md-6 col-lg-4">
    <div className="service-card-appoinment border p-4 text-center">
    <img src={request} alt="" style={{height:"70px"}}/>
      <h4 className="service-title text-primary mt-2">
        Look up your start service request
      </h4>
      <p className="service-appoinment-text ">
        Check on the details and status of your start service appointment.
      </p>
      <a href="#" className="service-appoinemt-link">
        <button className="learn-more-btn">Manage Appointments <span>&#8250;</span></button>
      </a>
    </div>
  </div>

  {/* Pilot Relight */}
  <div className="col-sm-12 col-md-6 col-lg-4">
    
    <div className="service-card-appoinment border p-4 text-center">
    <img src={Relight} alt="" style={{height:"70px"}} className=""/>
      <h4 className="service-title text-primary mt-2  three">Need Help Relighting Your Pilot Light? </h4>
      <p className="service-appoinment-text">
        Schedule your relight service before the weather gets cold.
      </p>
      <a href="#" className="service-appoinemt-link">
        <button className="learn-more-btn">Schedule Appointment <span>&#8250;</span></button>
      </a>
    </div>
  </div>
</div>

<h1 className="mt-5 service_appoinment-heading">More Serives</h1>
        {/* Restore Service Section */}
        <div className="container service-restore mt-5">
        
          <div className="row align-items-center mt-5">
            <div className="col-md-6 px-1">
              <img
                src={Restore_serviceimg}
                alt="Restore Service"
                className="img-fluid service-restore-img"
              />
            </div>
            <div className="col-md-6">
              <h2 className="restore-service-heading text-center mt-5">
                Restore Service
              </h2>
              <p className=" text-center restore-service-info ">
                If the meter is closed for non-payment of bills, contact us to
                inquire about the total charges due. Charges to restore service
                usually include the amount of any past due bills,
                deposit/additional deposit and the reconnection fee.
              </p>
              <div className="d-flex justify-content-center">
                <button className="learn-more-btn">
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Builder Services Section */}
        <div className="container service-restore mt-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h1 className="restore-service-heading text-center mt-5 ">
                Builder Services
              </h1>
              <p className="text-center restore-service-info">
                Submit an application for new construction projects to install a
                natural gas pipeline and/or meter, request to modify an existing
                one, or pay your construction project invoice.
              </p>
              <p className=" text-center restore-service-info">
                Visit this page to learn more about Building Services, to access
                the application for Natural Gas Service for Residential,
                Commercial, and Industrial New Construction, and to request
                modification of SoCalGas-owned meters and pipelines.
              </p>
              <div className="d-flex justify-content-center">
                <button className="learn-more-btn">
                  Contact Us
                </button>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <img
                src={Servie_builderimg}
                alt="Builder Services"
                className="img-fluid service-builder-img"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <div className="">{/* <img src={} alt="" /> */}</div>
          </div>
          <div className="col-9"></div>
        </div>

        {/* Frequently Asked Qustions */}
        <div className="service-faq-container">
          <div className="service-faq-header">
            <h1 className="service_appoinment-heading">Frequently asked questions</h1>
          </div>
          {faqData.map((faq, index) => (
            <div
              className={`service-faq-item ${
                activeIndex === index ? "active" : ""
              }`}
              key={index}
            >
              <button
                className="service-faq-question"
                onClick={() => handleClick(index)}
              >
                {faq.question}
                <span className="service-faq-icon">
                  {activeIndex === index ? "-" : "+"}
                </span>
              </button>
              <div
                className={`service-faq-answer ${
                  activeIndex === index ? "show" : ""
                }`}
              >
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
    
      </div>

    
    </>
  );
};

export default Service;
